import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { auth } from "../firebase-config";
import { Bars3Icon, XMarkIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import myLogo from "../public/prorecruitBusinessLogo.png";
import { useAuth } from "../useAuth";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function Navbar({ onCreateJob, extraButton, userData }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSolid, setIsSolid] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation("global");
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSolid(offset > 80);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const signout = useCallback(
    async (event) => {
      function noop() {}
      event ? event.preventDefault() : noop();

      fetch(`${API_ENDPOINT}/api/v1/usersession/sessionLogout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenantId: userData.tenantId,
        }),
        credentials: "include",
      })
        .then((r) => {
          signOut(auth).catch((error) => {
            console.error(
              `Sign-out failed. currentUser ${JSON.stringify(
                currentUser
              )}, ${error}`
            );
          });
        })
        .catch((error) => {
          console.error(
            `Sign-out failed. currentUser ${JSON.stringify(
              currentUser
            )}, ${error}`
          );
        });
    },
    [currentUser, userData]
  );

  const handleLogout = async () => {
    try {
      await signout();
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const handleExtraButton = async () => {
    navigate(extraButton.path);
  };

  const handleDashboardButton = async () => {
    navigate("/dashboard");
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsDropdownOpen(false);
  };

  return (
    <header className="sticky top-0 z-50">
      <nav
        className={`mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 ${isSolid ? "bg-prorecruitbgcolor" : "bg-gradient-to-b"}`}
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">ProRecruit</span>
            <img className="h-12 w-auto" src={myLogo} alt="ProRecruit" />
          </Link>
        </div>

        {/* Dynamic middle button */}
        {extraButton && extraButton.label && extraButton.path && (
          <div className="hidden lg:flex">
            <button
              onClick={handleExtraButton}
              className="hidden lg:block mx-2 text-sm font-semibold leading-6 text-gray-900 bg-navBarMiddleButtonBg hover:text-blue-500"
            >
              {extraButton.label}
            </button>
          </div>
        )}

        <div className="flex items-center">
          {/* Create Job Posting Button (desktop) */}
          {currentUser && (
            <button
              onClick={onCreateJob}
              className="hidden lg:block text-sm me-5 font-semibold leading-6 text-gray-900 bg-navBarMiddleButtonBg hover:text-blue-500 mr-4"
            >
              {t("Creer Offre D'Emploi")}
            </button>
          )}

          {currentUser && (
            <button
              onClick={handleDashboardButton}
              className="hidden lg:block text-sm me-5 font-semibold leading-6 text-gray-900 bg-navBarMiddleButtonBg hover:text-blue-500 mr-4"
            >
              {t("Dashboard")}
            </button>
          )}

          {/* Language Toggle */}
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="text-gray-900 bg-navBarMiddleButtonBg hover:text-blue-500"
            >
              <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg py-1 z-50">
                <button
                  onClick={() => changeLanguage("en")}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  English
                </button>
                <button
                  onClick={() => changeLanguage("fr")}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Français
                </button>
              </div>
            )}
          </div>

          {/* User Menu (desktop) */}
          {currentUser ? (
            <div className="hidden lg:flex items-center">
              <p className="text-sm font-semibold leading-6 text-gray-900 mr-2">
                {t("Bienvenue ,")} {currentUser.displayName}
              </p>
              <button
                onClick={handleLogout}
                className="text-sm font-semibold leading-6 text-gray-900 hover:text-blue-500 bg-logoutButtonColor"
              >
                {t("Se déconnecter")}
              </button>
            </div>
          ) : (
            // Login button (desktop)
            <Link
              to="/login"
              className="hidden lg:block text-sm font-semibold leading-6 text-gray-900 hover:text-blue-500"
            >
              {t("Se connecter")}
            </Link>
          )}

          {/* Mobile Menu Button */}
          <div className="lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900 hover:text-blue-500"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              {mobileMenuOpen ? (
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      <Transition
        show={mobileMenuOpen}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <Link to="/" className="mr-auto">
                  <span className="sr-only">ProRecruit</span>
                  <img className="h-12 w-auto" src={myLogo} alt="ProRecruit" />
                </Link>
                <button
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900 hover:text-blue-500"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 px-2 space-y-4">
                {/* Conditional render for mobile menu items */}
                {currentUser && (
                  <>
                    <button
                      onClick={onCreateJob}
                      className="block text-sm font-semibold leading-6 text-gray-900 hover:text-blue-500"
                    >
                      {t("Creer Offre D'Emploi")}
                    </button>
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {t("Bienvenue ,")} {currentUser.displayName}
                    </p>
                    <button
                      onClick={handleLogout}
                      className="block text-sm font-semibold leading-6 text-gray-900 hover:text-blue-500"
                    >
                      {t("Se déconnecter")}
                    </button>
                  </>
                )}
                {!currentUser && (
                  <Link
                    to="/login"
                    className="block text-sm font-semibold leading-6 text-gray-900 hover:text-blue-500"
                  >
                    {t("Se connecter")}
                  </Link>
                )}
                <div className="relative">
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="text-gray-900 hover:text-blue-500"
                  >
                    <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg py-1 z-50">
                      <button
                        onClick={() => changeLanguage("en")}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        English
                      </button>
                      <button
                        onClick={() => changeLanguage("fr")}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Français
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </header>
  );
}
