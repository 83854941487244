import React, { useEffect } from 'react';

const DialogflowMessenger = ({userId, userParams }) => {
  useEffect(() => {
    const scriptId = 'dialogflow-messenger-script';

    if (!document.getElementById(scriptId) && !customElements.get('df-messenger')) {
      // Load the Dialogflow Messenger script only if it's not already loaded
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1';
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      // Cleanup the script when the component is unmounted
      const script = document.getElementById(scriptId);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <df-messenger
      intent="WELCOME"
      chat-title="Support"
      agent-id="1e30c295-3dc3-4906-87d9-1b2a1890d3d3"//"36ee9bb6-dca2-4446-9c9c-d14b7e6a31d6"
      language-code="fr"
      user-id={userId}
      user-params={JSON.stringify(userParams)}
    ></df-messenger>
  );
};

export default DialogflowMessenger;
