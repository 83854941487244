// /utils/redux/userDataSlice.js
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../firebase-config";

const usersAdapter = createEntityAdapter({
  selectId: (userData) => userData.email,
});

const initialState = usersAdapter.getInitialState({ loading: false });

export const fetchAndSetUserData = createAsyncThunk(
  "userData/fetchAndSetUserData",
  async (email, { rejectWithValue }) => {
    try {
      const userDocRef = doc(db, `users`, email);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        auth.tenantId = userData.userData.originalTenantId || "KEOTech-sjayz";
        return userData.userData; // Return the fetched userData
      } else {
        return rejectWithValue("User document does not exist."); // Reject if document not found
      }
    } catch (error) {
      return rejectWithValue(error.message); // Reject with error message
    }
  }
);

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    removeUser: usersAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAndSetUserData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAndSetUserData.fulfilled, (state, action) => {
      usersAdapter.upsertOne(state, action.payload);
      state.loading = false;
    });
  },
});
// Selector function to access userData from the state
export const { selectAll: selectAllUsers } = usersAdapter.getSelectors(
  (state) => state.userData
);
// export const selectUserData = (state) => state.userData.data;

export const { removeUser } = userDataSlice.actions;
export default userDataSlice.reducer;
