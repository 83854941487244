import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { openCandidateDetailsModal } from "../utils/redux/candidateActions";
import CandidateScoreReason from "./CandidateScoreReason";
import CandidateStatusUpdateModal from "./CandidateStatusUpdateModal";

const CandidateItem = ({ candidate, tenantId, jobUuid  }) => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(openCandidateDetailsModal(candidate));
  };
  const handleOpenStatusModal = () => {
    setIsStatusModalOpen(true);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-3 cursor-pointer hover:bg-gray-50">
      <CandidateStatusUpdateModal
        isOpen={isStatusModalOpen}
        onClose={() => setIsStatusModalOpen(false)}
        candidate={candidate}
        tenantId={tenantId} 
        jobUuid={jobUuid}
        // onUpdateStatus={handleUpdateJobPostingStatus}
      />
      <div
        key={candidate.uuid}
        className="bg-white shadow-md rounded-lg p-4 mb-3 cursor-pointer hover:bg-gray-50"
        onClick={handleOpenModal}
      >
        <h2
          className={
            ["Rejeter", "Rejected"].includes(candidate.ApplicationStatus)
              ? "text-lg font-bold text-red-700"
              : "text-lg font-bold text-blue-700"
          }
        >
          {candidate.FullName}{" "}
          {["Rejeter", "Rejected"].includes(candidate.ApplicationStatus) &&
            "(Rejected)"}
        </h2>
      </div>
      <div>
        <p className="text-gray-600">
          {candidate.ContactInformation
            ? candidate.ContactInformation.Email
            : ""}
        </p>
        <p className="text-gray-600">
          {candidate.ContactInformation
            ? candidate.ContactInformation.Phone
            : ""}
        </p>

        <p className="text-gray-500 mt-2">
          Statut:{" "}
          <span
            className={
              candidate.ApplicationStatus === "Offered"
                ? "text-green-500"
                : ["Rejeter", "Rejected"].includes(candidate.ApplicationStatus)
                ? "text-red-500"
                : ""
            }
          >
            {candidate.ApplicationStatus}
          </span>{" "}
          <button
            onClick={() => handleOpenStatusModal()}
            className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm"
          >
            Changer Statut
          </button>
        </p>
        <p className="text-gray-500 mt-2">
          Classement:{" "}
          <span
            className={
              candidate.ApplicationStatus === "Offered"
                ? "text-green-500"
                : ["Rejeter", "Rejected"].includes(candidate.ApplicationStatus)
                ? "text-red-500"
                : ""
            }
          >
            {candidate.Score}/100 - Experiences sur 60, Educations sur 20
          </span>
        </p>
        <p className="text-gray-500 mt-2">
          Explication:{" "}
          <span>
            <CandidateScoreReason description={candidate.score_list} />
          </span>
        </p>
        {candidate.assessmentNames?
        candidate.assessmentNames.map((assessment)=>(
        <div
        className="bg-white shadow-md rounded-lg p-4 mb-3 cursor-pointer hover:bg-gray-50"><p className="text-gray-500 mt-2">
          <strong>Test d'evaluation: </strong>{assessment.title} 
          <span
            className={
              assessment.score > 50
                ? "text-green-500 p-4"
                : "text-red-500 p-4"
            }
          >
             Score : {assessment.score}/100
          </span>
        </p>
        <p>
                    <a
                      className="text-md text-blue-600 hover:underline hover:text-blue-700 hover:font-semibold"
                      href={`${process.env.REACT_APP_BASE_URL}assessmentresults/${assessment.link}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      result de l'evaluation
                    </a>
                  </p>
        <p className="text-gray-500 mt-2">
          Explication:{" "}
          <span>
            
          {assessment.description}
          </span>
        </p></div>
        )):""}
      </div>
    </div>
  );
};

export default CandidateItem;
