import React from "react";
import { Link } from "react-router-dom";

const JobPostingActions = ({ posting, userData, onOpenStatusModal }) => {
  return (
    <div className="mt-4 flex items-center space-x-4">
      <p>
        <a
          className="text-md text-blue-600 hover:underline hover:text-blue-700 hover:font-semibold"
          href={
            posting.linkToApply
              ? `${process.env.REACT_APP_BASE_URL}jobs/${posting.JobID}`
              : `${process.env.REACT_APP_BASE_URL}applications/${posting.uuid}/tenants/${userData.tenantId}`
          }
          rel="noreferrer"
          target="_blank"
        >
          Lien pour postuler
        </a>
      </p>
      <p
        className={
          new Date(posting.DateLimiteDeCandidature).getTime >
          new Date().getTime()
            ? "text-md text-red-300"
            : "text-md text-black-600"
        }
      >
        <strong>Date limite de candidature:</strong>{" "}
        {new Date(posting.DateLimiteDeCandidature).toLocaleDateString("fr-FR", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </p>
      <p className={posting.Statut === "Offres" ? "text-green-600" : ""}>
        <strong>Statut:</strong> {posting.Statut} {"    "}
        <button
          onClick={() => onOpenStatusModal(posting)}
          className="px-4 py-2 bg-blue-400 text-white rounded hover:bg-blue-700"
        >
          Changer Statut
        </button>
      </p>
      <Link
        to={`/candidates/${posting.uuid}/tenants/${posting.tenantId}`}
        state={{ posting }}
        className="text-blue-500 hover:underline"
      >
        Voir les candidats:{" "}
        {posting.numberOfApplicants ? posting.numberOfApplicants : 0}
      </Link>
    </div>
  );
};

export default JobPostingActions;
