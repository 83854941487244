import React, { useState, useEffect } from "react";
import { db } from "../firebase-config";
import { getDocs, collection } from "firebase/firestore";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // console.log(`Starting ${jobID}`);
    try {
      setReviews([]);
      getDocs(collection(db, "reviews")).then((querySnapshot) => {
        var arr = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          arr.push(doc.data());
          //   setReviews([...reviews, doc.data()]);
        });
        setReviews(arr);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  console.log(reviews);
  const totalCount = reviews.length;
  console.log(totalCount);
  const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
  const averageRating = totalRating / totalCount;

  const renderStars = (rating) => {
    console.log(rating);
    const stars = [];
    for (let i = 1; i <= 10; i++) {
      stars.push(
        <span
          key={i}
          style={{
            display: "inline-block",
            width: "7px",
            overflow: "hidden",
            direction: i % 2 === 0 ? "rtl" : "ltr",
            cursor: "pointer",
            color: i <= rating * 2 ? "gold" : "gray",
          }}
        >
          ★
        </span>
      );
    }
    return stars;
  };
  const renderAverageStars = (averageRating) => {
    const percentage = (averageRating / 5) * 100;
    return (
      <div>
        {/* <span style={{ display: "inline-block", width: "100px" }}>
          {renderStars(5)}
        </span> */}
        <span
          style={{
            display: "inline-block",
            width: `${percentage}%`,
            overflow: "hidden",
          }}
        >
          {renderStars(averageRating)}
        </span>
      </div>
    );
  };

  return (
    <div>
      <h1>User Ratings Dashboard</h1>
      <p>Total Reviews: {totalCount}</p>
      <p>Average Rating: {averageRating.toFixed(1)}</p>
      <p>Average Rating Stars: {renderAverageStars(averageRating)}</p>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #ddd",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                padding: "8px",
                backgroundColor: "#f2f2f2",
                fontWeight: "bold",
              }}
            >
              Name
            </th>
            <th
              style={{
                padding: "8px",
                backgroundColor: "#f2f2f2",
                fontWeight: "bold",
              }}
            >
              Email
            </th>
            <th
              style={{
                padding: "8px",
                backgroundColor: "#f2f2f2",
                fontWeight: "bold",
              }}
            >
              Phone Number
            </th>
            <th
              style={{
                padding: "8px",
                backgroundColor: "#f2f2f2",
                fontWeight: "bold",
              }}
            >
              Rating
            </th>
            <th
              style={{
                padding: "8px",
                backgroundColor: "#f2f2f2",
                fontWeight: "bold",
              }}
            >
              Review
            </th>
          </tr>
        </thead>
        <tbody>
          {reviews.map((review, index) => (
            <tr key={index}>
              <td style={{ padding: "8px" }}>{review.fullName}</td>
              <td style={{ padding: "8px" }}>{review.email}</td>
              <td style={{ padding: "8px" }}>{review.phoneNumber}</td>
              <td style={{ padding: "8px" }}>
                {review.rating} {renderStars(review.rating)}
              </td>
              <td style={{ padding: "8px" }}>{review.review}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Reviews;
