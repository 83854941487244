// /utils/redux/AssessmentsSlice.js
import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase-config";

const ASSESSMENTS_PER_PAGE = 20;

export const fetchAssessments = createAsyncThunk(
  "assessments/fetchAssessments",
  async ({ tenantId }, { getState }) => {
    try {
      const state = getState();
      const lastVisible = state.assessments.lastVisible;
      let q = query(
        collection(db, `assessments`),
        where("target", "in", ["all", `${tenantId}`]),
        orderBy("target", "desc")
      );

      if (lastVisible) {
        q = query(q, startAfter(lastVisible));
      }

      q = query(q, limit(ASSESSMENTS_PER_PAGE));

      const querySnapshot = await getDocs(q);

      const assessmentsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return {
        assessments: assessmentsData,
        lastVisible: assessmentsData[assessmentsData.length - 1],
      };
    } catch (error) {
      console.log("Error fetching job postings:", error);
      throw error;
    }
  }
);

export const loadMoreAssessments = createAsyncThunk(
  "assessments/loadMoreAssessments",
  async ({ tenantId }, { getState }) => {
    try {
      const state = getState();
      const lastVisible = state.assessments.lastVisible;
      if (!lastVisible) return;

      let q = query(
        collection(db, `assessments`),
        where("target", "in", ["all", `${tenantId}`]),
        orderBy("createdOn", "desc"),
        startAfter(lastVisible),
        limit(ASSESSMENTS_PER_PAGE)
      );

      const querySnapshot = await getDocs(q);
      const newAssessments = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return {
        assessments: newAssessments,
        lastVisible: newAssessments[newAssessments.length - 1],
      };
    } catch (error) {
      console.error("Error loading more job postings:", error);
      throw error;
    }
  }
);

const AssessmentsAdapter = createEntityAdapter({
  selectId: (assessment) => assessment.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const initialState = AssessmentsAdapter.getInitialState({
  loading: false,
  error: null,
  lastVisible: null,
  hasMore: true,
});

const AssessmentsSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {
    // ... other reducers (if any) ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssessments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssessments.fulfilled, (state, action) => {
        state.loading = false;
        AssessmentsAdapter.setAll(state, action.payload.assessments);
        state.lastVisible = action.payload.lastVisible;
        state.hasMore = action.payload.assessments.length === ASSESSMENTS_PER_PAGE;
      })
      .addCase(fetchAssessments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loadMoreAssessments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadMoreAssessments.fulfilled, (state, action) => {
        state.loading = false;
        AssessmentsAdapter.addMany(state, action.payload.assessments);
        state.lastVisible = action.payload.lastVisible;
        state.hasMore = action.payload.assessments.length === ASSESSMENTS_PER_PAGE;
      })
      .addCase(loadMoreAssessments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectAssessments,
} = AssessmentsAdapter.getSelectors((state) => state.assessments);

export const selectAssessmentsLoading = (state) => state.assessments.loading;
export const selectAssessmentsError = (state) => state.assessments.error;

export default AssessmentsSlice.reducer;
