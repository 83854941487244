// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./useAuth";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import JobPostingsPage from "./pages/JobPostingsPage";
import CandidateListPage from "./pages/CandidateListPage";
import ApplicationsPage from "./pages/ApplicationsPage";
import ApplicationsPage2 from "./pages/ApplicationsPage2";
import ThankYouPage from "./pages/ThankYouPage";
import TestPage from "./pages/test";
import AssessmentPage from "./pages/Assessments";
import AssessmentResultsPage from "./pages/AssessmentResults";
import Reviews from "./pages/Reviews";
import Pricing from "./pages/Pricing";
import LicenseBuyingPage from "./pages/LicenseBuyingPage";
import JobPostingDetailsPage from "./pages/JobPostingDetailsPage";
import DashboardPage from "./pages/DashboardPage";
import CreateAssessment from "./pages/CreateAssessment";
import UpdateAssessment from "./pages/UpdateAssessment";
import { fetchAndSetUserData } from "./utils/redux/userDataSlice";
import { useSelector, useDispatch } from "react-redux";
import DialogflowMessenger from "./components/DialogflowMessenger";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1>404 - {t('404_message')}</h1>
    </>
  );
};

const PrivateRoute = ({ element, ...rest }) => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  return currentUser ? (
    (dispatch(fetchAndSetUserData(currentUser.email)), element)
  ) : (
    <Navigate to="/signin" replace />
  );
};

function App() {
  const { t } = useTranslation();
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/checkout" element={<LicenseBuyingPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/dashboard"
            element={<PrivateRoute element={<DashboardPage />} />}
          />
          <Route
            path="/createAssessment"
            element={<PrivateRoute element={<CreateAssessment />} />}
          />
          <Route
            path="/updateAssessment/:id"
            element={<PrivateRoute element={<UpdateAssessment />} />}
          />
          <Route
            path="/job-postings"
            element={<PrivateRoute element={<JobPostingsPage />} />}
          />
          <Route
            path="/jobpostings"
            element={<PrivateRoute element={<JobPostingsPage />} />}
          />
          <Route
            path="/"
            element={<PrivateRoute element={<JobPostingsPage />} />}
          />
          <Route
            path="/jobpostings/:jobId/tenants/:tenantId"
            element={<PrivateRoute element={<JobPostingDetailsPage />} />}
          />
          <Route
            path="/candidates/:jobID/tenants/:tenantID"
            element={<PrivateRoute element={<CandidateListPage />} />}
          />
          <Route
            path="/applications/:jobID/tenants/:tenantID"
            element={<PrivateRoute element={<ApplicationsPage />} />}
          />
          <Route
            path="/assessmentresults/:assessmentID/test/:testID"
            element={<PrivateRoute element={<AssessmentResultsPage />} />}
          />
          <Route
            path="/assessments/:assessmentID/test/:testID"
            element={<AssessmentPage />}
          />
          <Route path="/jobs/:jobID" element={<ApplicationsPage2 />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/kwami" element={<Reviews />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
