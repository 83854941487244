import React from "react";
import Modal from "./Modal";

const LicenseRequiredModal = ({
  isOpen,
  onClose,
  onGetLicense,
  onLearnMore,
}) => {
  if (!isOpen) {
    return null; // Don't render if not open
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-8 max-w-md">
        <h2 className="text-xl font-bold mb-4">Votre licence est requise</h2>
        <p className="mb-6">
          Il semble que vous n'ayez pas de licence Basic ou Pro active. Pour
          continuer à utiliser toutes les fonctionnalités, veuillez vous
          procurer une licence.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onLearnMore}
            className="px-4 py-2 rounded-md bg-gray-200 hover:bg-gray-300 text-gray-700"
          >
            En savoir plus
          </button>
          <button
            onClick={onGetLicense}
            className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white"
          >
            Obtenir une licence
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 rounded-md bg-gray-200 hover:bg-gray-300 text-gray-700"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default LicenseRequiredModal;
