import { Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import TawkToChat from "../components/TawkToChat";
import { signOut } from "firebase/auth";
import { auth, analytics, db } from "../firebase-config";
import { collection, doc, setDoc, getDoc, updateDoc, increment } from "firebase/firestore";
import { useAuth } from "../useAuth";
import { ColorRing } from "react-loader-spinner";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
// import DialogflowMessenger from "../components/DialogflowMessenger";
import CreateJobModal from "../components/createJobModal";
import Navbar from "../components/Navbar";
import StatusModal from "../components/StatusModal";
import JobPostingActions from "../components/JobPostingActions";
import { sanitizeInput } from "../utils/inputSanitizer";
import JobPostingDescription from "../components/JobPostingDescription";
import { useSelector, useDispatch } from "react-redux";
import LicenseRequiredModal from "../components/LicenseRequiredModal";
import {
  fetchJobPostings,
  selectJobPostings,
  selectJobPostingsLoading,
  selectJobPostingsError,
  loadMoreJobPostings,
} from "../utils/redux/jobPostingsSlice";
import {
  selectAllUsers,
  fetchAndSetUserData,
} from "../utils/redux/userDataSlice";
import {
  fetchAndSetCompanyData,
  selectAllCompanys,
} from "../utils/redux/companySlice";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const JobPostingsPage = () => {
  const { t } = useTranslation("jobpostingpage");
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const userData = useSelector(selectAllUsers)[0];
  console.log(currentUser);
  console.log(userData);
  const companyData = useSelector(selectAllCompanys);
  console.log(companyData);
  const jobPostings = useSelector(selectJobPostings);
  const loading = useSelector(selectJobPostingsLoading);
  const error = useSelector(selectJobPostingsError);
  // const [showChatbot] = useState(true);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [hasAnyLicense, setHasAnyLicense] = useState(false);
  const [proLicenseCount, setProLicenseCount] = useState(0);
  const [basicLicenseCount, setBasicLicenseCount] = useState(0);
  const [currentJobPosting, setCurrentJobPosting] = useState();
  const [newJobData, setNewJobData] = useState({});
  const [pageURL, setPageURL] = useState(0);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [tenantId, setTenantId] = useState("")

  useEffect(() => {
    setPageURL(window.location.href);
  }, [pageURL]);

  useEffect(() => {

    const fetchData = async () => {
      if (currentUser) {
        console.log(currentUser)
        setUserId(analytics, currentUser ? currentUser.uid : "");
        setUserProperties(analytics, {
          fullName: currentUser ? currentUser.displayName : "unknown",
          email: currentUser ? currentUser.email : "unknown",
        });
        // Wait for fetchAndSetUserData to complete:
        const u = await dispatch(fetchAndSetUserData(currentUser.email));
        console.log(u)
        console.log(u.tenantId)
        console.log(u.payload.tenantId)
        setTenantId(u.tenantId)
        const c = await dispatch(fetchAndSetCompanyData(u.payload.tenantId));
        console.log(c)
        setProLicenseCount(c.payload.proLicense);
        setBasicLicenseCount(c.payload.basicLicense);

        console.log(c.payload);
        console.log(u.payload);
        if (c.payload.proLicense + c.payload.basicLicense <= 0) {
          setHasAnyLicense(false);
        } else {
          setHasAnyLicense(true);
        }
        console.log(c);
        await dispatch(fetchJobPostings({ tenantId: u.payload.tenantId }));
      }
    };
    fetchData(); // Call the async function
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (userData) {
      dispatch(fetchJobPostings({ tenantId: userData.tenantId }));
    }
  }, [userData, dispatch]);

  console.log(companyData);
  console.log(userData);

  const handleSignOut = useCallback(async () => {
    try {
      logEvent(analytics, "select_content", {
        content_type: "ClickedSignout",
        content_id: `${currentUser.uid}`,
      });

      await fetch(`${API_ENDPOINT}/api/v1/usersession/sessionLogout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenantId: currentUser.tenantId,
        }),
        credentials: "include",
      });

      await signOut(auth);
    } catch (error) {
      console.error(
        `Sign-out failed. currentUser ${JSON.stringify(currentUser)}, ${error}`
      );
    }
  }, [currentUser]);

  const handleCreateJobChange = (e) => {
    console.log(newJobData)
    console.log(e)
    // Check if e is an event or a value from react-select
    if (e && e.target) {
      // Handle standard input change
      setNewJobData({ ...newJobData, [e.target.name]: e.target.value });
  } else {
      // Handle react-select change for assessments
    console.log(e)
      
    // Merge new assessments with the existing ones
    setNewJobData(newJobData => ({
      ...newJobData,
      assessments: Array.isArray(e) ? e : [...(newJobData.assessments || []), e]
    }));
  }
  };
  
  const handleGetLicense = () => {
    // Logique pour rediriger l'utilisateur vers la page d'achat de licence
    window.location.href = "/checkout"; // Exemple
  };

  const handleLearnMore = () => {
    // Logique pour rediriger l'utilisateur vers la page d'informations sur les licences
    window.location.href = "/pricing"; // Exemple
  };
  const generateShortCode = async (docRef) => {
    const applicationUrlIdDocRef = doc(db, "job", "5tdpJAv6JNwmawkDKPSH");
    const applicationUrlIdDoc = await getDoc(applicationUrlIdDocRef);

    if (applicationUrlIdDoc.exists()) {
      const data = applicationUrlIdDoc.data();
      while (true) {
        let applicationUrlId = Math.random().toString(36).substring(2, 8);
        if (!data[applicationUrlId]) {
          await setDoc(
            applicationUrlIdDocRef,
            { [applicationUrlId]: docRef },
            { merge: true }
          );
          return applicationUrlId;
        }
      }
    } else {
      console.error("Document '5tdpJAv6JNwmawkDKPSH' not found!");
      throw new Error("Document not found for short code generation");
    }
  };

  const handleOpenStatusModal = (posting) => {
    logEvent(analytics, "select_content", {
      content_type: "ClickChangeStatus",
      content_id: posting.uuid,
    });
    console.log(posting)
    setCurrentJobPosting(posting);
    setIsStatusModalOpen(true);
  };

  const handleUpdateJobPostingStatus = async (uuid, newStatus) => {
    logEvent(analytics, "select_content", {
      content_type: "ChangedJobPostingStatus",
      content_id: `${currentJobPosting.uuid}${newStatus}`,
    });

    try {
      const updatedJobPosting = { ...currentJobPosting, Statut: newStatus };
console.log(userData)
console.log(currentJobPosting)

      await fetch(
        `${API_ENDPOINT}/api/v1/tenants/${userData.tenantId}/jobpostings/${currentJobPosting.uuid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedJobPosting),
        }
      );
      if (
        updatedJobPosting.Statut === "Completer" ||
        updatedJobPosting.Statut === "Annuler"
      ) {
        const payload = {
          documentId: updatedJobPosting.uuid,
          tenantId: userData.tenantId,
          status: updatedJobPosting.Statut,
          tenantName: updatedJobPosting.Entreprise,
          recruiterName: userData.displayName,
          recruiterEmail: userData.email,
          jobTitle: updatedJobPosting.Titre,
          fromCollectionName: "active",
          collectionName:
            updatedJobPosting.Statut === "Completer" ? "completed" : "canceled",
        };

        await fetch(`${API_ENDPOINT}/api/v1/tasks`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            url: `https://us-central1-${PROJECT_ID}.cloudfunctions.net/cancel-job-descriptions`,
            payload: payload,
          }),
        });
      }

      setIsStatusModalOpen(false);
    } catch (error) {
      console.error("Error updating job posting status:", error);
    }
  };

  const toggleCreateModal = () => {
    logEvent(analytics, "select_content", {
      content_type: "ClickCreateJobPosting",
      content_id: "New",
    });
    console.log(hasAnyLicense);
    console.log(basicLicenseCount);
    console.log(proLicenseCount);
    hasAnyLicense
      ? setIsCreateModalOpen(!isCreateModalOpen)
      : setIsLicenseModalOpen(true);
  };

  const handleCreateJobSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData(event.target);
      const newJobDataObject = Object.fromEntries(formData.entries());
      const sanitizedJobData = Object.fromEntries(
        Object.entries(newJobDataObject).map(([key, value]) => [
          key,
          sanitizeInput(value),
        ])
      );

      logEvent(analytics, "select_content", {
        content_type: "handleCreateJobSubmit",
        content_id: newJobDataObject ? newJobDataObject.Titre : "",
      });
      console.log(sanitizedJobData)
      const docRef = doc(
        collection(db, `job-descriptions/${userData.tenantId}/active`)
      );

      const applicationUrlId = await generateShortCode(docRef);
      var licenseUpdateJson = {"companyData.basicLicense": increment(-1)}
      sanitizedJobData.type = "Basic"
      if (sanitizedJobData.jobOfferType.toLowerCase().includes("pro")){
         licenseUpdateJson = {"companyData.proLicense": increment(-1)}
         sanitizedJobData.type = "Prof"
      }
      const jobPosting = {
        licenseType: sanitizedJobData.jobOfferType,
        JobID: applicationUrlId,
        resumeAnalysisQuota: 30000,
        type: sanitizedJobData.type,
        uuid: docRef.id,
        Titre: sanitizedJobData.Titre || "",
        Entreprise: sanitizedJobData.Entreprise,
        EntrepriseDescription: sanitizedJobData.DescriptionDeLentreprise,
        Lieu: sanitizedJobData.Lieu,
        TypeDePoste: sanitizedJobData.TypeDePoste,
        Secteur: sanitizedJobData.Secteur,
        CategorieDeRole: sanitizedJobData.CategorieDeRole,
        Description: sanitizedJobData.Description,
        Qualifications: sanitizedJobData.Qualifications,
        NiveauDExperience: sanitizedJobData.NiveauDExperience,
        CompetencesRequises: sanitizedJobData.CompetencesRequises
          ? sanitizedJobData.CompetencesRequises.split(",")
          : [],
        additionalInformation: sanitizedJobData.additionalInformation,
        // FourchetteDeSalaire: sanitizedJobData.FourchetteDeSalaire,
        Avantages: sanitizedJobData.Avantages
          ? sanitizedJobData.Avantages.split(",")
          : [],
        DateLimiteDeCandidature: sanitizedJobData.DateLimiteDeCandidature,
        PublicationDate: sanitizedJobData.DateDePublication,
        ProcedureDeCandidature: "",
        minimumRelevantYearsOfExperience:
        newJobDataObject.minimumRelevantYearsOfExperience,
        linkToApply: `${process.env.REACT_APP_BASE_URL}jobs/${applicationUrlId}`,
        Responsibilities: sanitizedJobData.Responsibilities || "",
        InformationsDeContact: {
          NomDuContactRH: sanitizedJobData.NomDuContactRH,
          Email: sanitizedJobData.Email,
          Telephone: sanitizedJobData.Telephone,
        },
        CreatedBy: {
          fullName: userData.displayName||"",
          email: userData.email,
          uuid: userData.uuid || "",
        },
        createdOn: new Date().toISOString(),
        Statut: "Submissions",
        tenantId: userData.tenantId,
        // LogoDeLentreprise: sanitizedJobData.LogoDeLentreprise,
        // DeclarationDeDiversite: sanitizedJobData.DeclarationDeDiversite,
      };

      console.log(sanitizedJobData)
      console.log(docRef)
      const docAdded = await setDoc(docRef, jobPosting);
      console.log(docAdded);
      const companyRef = doc(
        collection(db, `company`), `${userData.tenantId}`
      );
      
      const putCompany = await updateDoc(companyRef, licenseUpdateJson)
      console.log(putCompany);

      logEvent(analytics, "createdJobPosting", {
        tenantId: userData.tenantId,
        jobId: jobPosting.uuid,
        email: userData.email,
      });

      await fetch(`${API_ENDPOINT}/api/v1/tasks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: `https://us-central1-${PROJECT_ID}.cloudfunctions.net/job-description-txt-builder`,
          payload: jobPosting,
        }),
      });

      alert("jobCreatedSuccess");
      setIsCreateModalOpen(false);
    } catch (error) {
      console.error("Error creating job posting:", error);
      console.log(error)
      alert("errorCreatingJobPosting");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center">
        <h1 className="text-3xl font-bold text-red-500 mb-4">
          500 Internal Server Error
        </h1>
        <p className="text-lg text-gray-600">
          {t("errorLoading")}: {error}
        </p>
      </div>
    );
  }

  return (
    <>
      <Navbar onSignOut={handleSignOut} onCreateJob={toggleCreateModal} userData={userData}/>
      {isLicenseModalOpen && (
        <LicenseRequiredModal
          isOpen={isLicenseModalOpen}
          onClose={() => setIsLicenseModalOpen(false)}
          onGetLicense={handleGetLicense}
          onLearnMore={handleLearnMore}
        />
      )}

      <CreateJobModal
        isOpen={isCreateModalOpen}
        onClose={toggleCreateModal}
        onSubmit={handleCreateJobSubmit}
        onChange={handleCreateJobChange}
        data={newJobData}
        company={companyData}
      />

      <StatusModal
        isOpen={isStatusModalOpen}
        onClose={() => setIsStatusModalOpen(false)}
        currentJobPosting={currentJobPosting}
        onUpdateStatus={handleUpdateJobPostingStatus}
      />

      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-800">
            {t("jobPostings")}: {jobPostings.length}
          </h1>
        </div>

        {jobPostings.length > 0 ? (
          <ul>
            {jobPostings.map((posting, index) => (
              <li
                key={posting.uuid}
                className={`bg-white rounded-lg shadow-md p-6 mb-4 ${
                  index !== 0 ? "mt-6" : ""
                }`}
              >
                <Link
                  to={`/jobpostings/${posting.uuid}/tenants/${posting.tenantId}`}
                  className="text-xl font-bold text-blue-500 hover:underline"
                >
                  {posting.Titre} - {posting.JobID}
                </Link>
                <p className="text-gray-600">
                  {posting.Entreprise}, {posting.Lieu}
                </p>
                <a
                  className="text-blue-500 hover:underline"
                  href={
                    posting.linkToApply
                      ? `${process.env.REACT_APP_BASE_URL}jobs/${posting.JobID}`
                      : `${process.env.REACT_APP_BASE_URL}applications/${posting.uuid}/tenants/${userData.tenantId}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("applyLink")}
                </a>
                <p
                  className={
                    new Date(posting.DateLimiteDeCandidature).getTime() +
                      86400000 >
                    new Date().getTime()
                      ? "text-gray-500"
                      : "text-red-600"
                  }
                >
                  {t("applicationDeadline")}:{" "}
                  {new Date(posting.DateLimiteDeCandidature).toLocaleDateString(
                    "fr-FR",
                    {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
                </p>
                <p className="text-gray-600">
                  {t("Status")}:{" "}
                  <span
                    className={
                      posting.Statut === "Offres" ? "text-green-500" : ""
                    }
                  >
                    {posting.Statut}
                  </span>{" "}
                  <button
                    onClick={() => handleOpenStatusModal(posting)}
                    className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm"
                  >
                    {t("changeStatus")}
                  </button>
                </p>
                <Link
                  to={`/candidates/${posting.uuid}/tenants/${posting.tenantId}`}
                  state={{ userData, posting }}
                  className="text-blue-500 hover:underline"
                >
                  {t("viewCandidates")}:{" "}
                  {posting.numberOfApplicants ? posting.numberOfApplicants : 0}
                </Link>
                <JobPostingDescription description={posting.Description} />
                <JobPostingActions
                  posting={posting}
                  userData={userData}
                  onOpenStatusModal={handleOpenStatusModal}
                />
                {/* ... (rest of your job posting content) ... */}
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">
            {t("noJobToDisplay")}
          </p>
        )}
        {/* Load More Button */}
        {jobPostings.length > 0 && (
          <div className="flex justify-center mt-6">
            <button
              onClick={() =>
                dispatch(loadMoreJobPostings({ tenantId: userData.tenantId }))
              }
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {t("loadMore")}
            </button>
          </div>
        )}
      </div>
      <TawkToChat/>

      {/* {showChatbot && (
        <DialogflowMessenger
          userId={userData.uuid}
          userParams={{ name: userData.displayName }}
        />
      )} */}
    </>
  );
};

export default JobPostingsPage;
