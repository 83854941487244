import React from 'react';

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  // Function to handle click outside of the modal
  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-background') {
      onClose();
    }
  };

  return (
    <div id="modal-background" className="fixed inset-0 bg-black bg-opacity-40 z-50 flex justify-center items-start overflow-y-auto pt-10" onClick={handleOutsideClick}>
      <div className="bg-white p-5 rounded-lg shadow-xl max-w-3xl w-full mx-4 my-10 relative">
        <h2 className="text-2xl font-semibold mb-4">{title}</h2>
        <button className="absolute top-0 right-0 mt-2 mr-2 text-lg font-semibold text-gray-600 hover:text-black-800 hover:bg-green-800 hover:font-bold" onClick={onClose}>X</button>
        <div className="overflow-y-auto max-h-[80vh]">{children}</div>
      </div>
    </div>
  );
};

export default Modal;

