import React, { useState ,useEffect} from "react";
import Modal from "./Modal";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../useAuth";
import Select from 'react-select';
import {
  fetchAndSetCompanyData,
  selectAllCompanys,
} from "../utils/redux/companySlice";
import {
  fetchAssessments,
  selectAssessments,
} from "../utils/redux/assessmentSlice";

const CreateJobModal = ({
  isOpen,
  onClose,
  onSubmit,
  onChange,
  data,
  company,
}) => {
  const [jobOfferType, setJobOfferType] = useState("pro"); // Default to basic job offer type
  const companyData = useSelector(selectAllCompanys);
  const assessments = useSelector(selectAssessments);
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  console.log(companyData);
  console.log(company);
  console.log(transformArray(assessments));

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchAndSetCompanyData({ tenantId: currentUser.tenantId }));
      dispatch(fetchAssessments({ tenantId: currentUser.tenantId }));
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    data.assessments = selectedAssessments;
  }, [selectedAssessments]);
  
  const handleJobOfferTypeChange = (event) => {
    setJobOfferType(event.target.value);
  };

  function transformArray(inputArray) {
    try {
      return inputArray.map(item => ({
          value: item.id,
          label: item.title
      }));
      
    } catch (error) {
      console.log(error)
    }
    
}
console.log(transformArray(assessments))
const handleAssessmentChange = (selected) => {
  setSelectedAssessments(selected);
};
  const hasLicense = (type) => {
    if (type === "basic") return companyData[0]?companyData[0].basicLicense > 0:false;
    if (type === "pro") return companyData[0]?companyData[0].proLicense > 0:false;
    return false;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Create New Job Posting">
      <form onSubmit={onSubmit} className="space-y-4">
        <div>
          <label>
            <input
              type="radio"
              name="jobOfferType"
              value="basic"
              checked={jobOfferType === "basic"}
              onChange={handleJobOfferTypeChange}
            />
            Basic Job Offer ({companyData[0]?companyData[0].basicLicense:0})
          </label>
          <label className="ml-4">
            <input
              type="radio"
              name="jobOfferType"
              value="pro"
              checked={jobOfferType === "pro"}
              onChange={handleJobOfferTypeChange}
            />
            Pro Job Offer ({companyData[0]?companyData[0].proLicense:0}) {"  "}
            <p><a href="/checkout" className="text-blue-600 underline">
                buy more licenses
              </a>{" "}</p>
          </label>
        </div>

        {!hasLicense(jobOfferType) && (
          <div className="mt-2 p-4 bg-red-100 text-red-800 rounded">
            <p>
              You do not have a {jobOfferType === "basic" ? "Basic" : "Pro"}{" "}
              License. Please{" "}
              <a href="/checkout" className="text-blue-600 underline">
                buy the license
              </a>{" "}
              or choose the other type.
            </p>
          </div>
        )}

        {hasLicense(jobOfferType) && (
          <>
            <div>
              <label>
                Titre{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <input
                  name="Titre"
                  type="text"
                  required
                  value={data.Titre || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Nom de l'Entreprise{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <input
                  name="Entreprise"
                  type="text"
                  value={data.Entreprise || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Description De L'entreprise
                <textarea
                  name="DescriptionDeLentreprise"
                  type="text"
                  value={data.DescriptionDeLentreprise || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Lieu{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important. Ville Pays
                </span>
                <input
                  name="Lieu"
                  type="text"
                  value={data.Lieu || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Date De Publication{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <input
                  name="DateDePublication"
                  type="date"
                  required
                  value={data.DateDePublication || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Nom Du Contact RH{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <input
                  name="NomDuContactRH"
                  type="text"
                  value={data.NomDuContactRH || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Email du RH{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <input
                  name="Email"
                  type="email"
                  value={data.Email || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Telephone du RH
                <input
                  name="Telephone"
                  type="tel"
                  value={data.Telephone || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Type de Poste{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <select
                  name="TypeDePoste"
                  required
                  value={data.TypeDePoste || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                >
                  <option value="">Select Type de Poste</option>
                  <option value="Temps plein">Temps plein</option>
                  <option value="Temps partiel">Temps partiel</option>
                  <option value="Contrat">Contrat</option>
                  <option value="Stage">Stage</option>
                  <option value="Freelance">Freelance</option>
                </select>
              </label>
            </div>

            <div>
              <label>
                Secteur
                <input
                  name="Secteur"
                  type="text"
                  value={data.Secteur || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Date Limite De Candidature{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <input
                  name="DateLimiteDeCandidature"
                  type="date"
                  required
                  value={data.DateLimiteDeCandidature || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Categorie de Role{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <input
                  name="CategorieDeRole"
                  type="text"
                  required
                  value={data.CategorieDeRole || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Niveau d'Experience{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <select
                  name="NiveauDExperience"
                  required
                  value={data.NiveauDExperience || ""}
                  onChange={onChange}
                  className="mt-1 block w-md rounded-md border-black-300 shadow-md"
                >
                  <option value="">Choisir le Niveau d'Experience</option>
                  <option value="Stage">Stage</option>
                  <option value="Debutant">Debutant</option>
                  <option value="Mid">Mid</option>
                  <option value="Senior">Senior</option>
                  <option value="Manager">Manager</option>
                  <option value="Directeur">Directeur</option>
                  <option value="Executif">Executif</option>
                </select>
              </label>
            </div>

            <div>
              <label>
                Description de l'emploi{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <textarea
                  name="Description"
                  required
                  value={data.Description || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-700 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Experience minimum requise en annees{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <input
                  name="minimumRelevantYearsOfExperience"
                  type="number"
                  required
                  value={data.minimumRelevantYearsOfExperience || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Qualifications professionnelles{" "}
                <span style={{ color: "red", font: "small-caption" }}>
                  Important
                </span>
                <textarea
                  name="Qualifications"
                  value={data.Qualifications || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Responsibilities
                <textarea
                  name="Responsibilities"
                  type="text"
                  value={data.Responsibilities || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>

            <div>
              <label>
                Informations Supplémentaires
                <textarea
                  name="additionalInformation"
                  type="text"
                  value={data.additionalInformation || ""}
                  onChange={onChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-md"
                />
              </label>
            </div>
            <div>
            <label> Test d'Evaluations
            {jobOfferType === "basic" ? " - Les tests d'evaluations ne sont disponible que pour les versions Pro." : <Select
        isMulti
        options={transformArray(assessments)}
        name="assessments"
        value={data.assessments||[]}
        onChange={onChange}
        // defaultValue={[transformArray(assessments)[0], transformArray(assessments)[1]]}
      />
        }</label></div>

            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Submit
            </button>
          </>
        )}
      </form>
    </Modal>
  );
};

export default CreateJobModal;
