// SignUp.js
import React, { useEffect, useState } from "react";
import { auth } from "./firebase-config"; // Assuming Firebase authentication setup
import { Country } from "country-state-city";
import { ColorRing } from "react-loader-spinner";

import CongratulatoryMessage from "./components/CongratulatoryMessage";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function SignUp() {
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchMessage, setPasswordMatchMessage] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [error, setError] = useState(""); // State to hold the error message
  const [selectedCountryisoCode, setSelectedCountryisoCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reference, setReference] = useState("");

  auth.tenantId = "KEO-Tech-6380h";

  useEffect(() => {
    setTenantId(tenantId);
  }, [setTenantId, tenantId]);

  useEffect(() => {
    setFullName(`${firstName} ${lastName}`);
  }, [firstName, lastName]);

  useEffect(() => {
    if (password !== confirmPassword) {
      setPasswordMatchMessage("Passwords do not match");
    } else {
      setPasswordMatchMessage("");
    }
  }, [password, confirmPassword]);

  // Handle country change
  const handleCountryChange = (event) => {
    event.preventDefault();
    const isoCode = event.target.value;
    setSelectedCountryisoCode(isoCode);
    setSelectedCountry(Country.getCountryByCode(isoCode));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(""); // Reset the error message before attempting to sign in
    auth.tenantId = tenantId;
    const payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      password: password,
      displayName: fullName,
      companyName: companyName,
      companyAddress: companyAddress,
      companyCity: companyCity,
      companyCountry: selectedCountry,
      reference: reference,
    };
    fetch(`${API_ENDPOINT}/api/v1/tenantsAdmin/KEOTech-sjayz/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        setLoading(false);
        if (response && response.status === 200) {
          setIsSignedUp(true);
          console.log(response);
          console.log(response.json());
        } else {
          console.log(response);
          response.json().then((data) => {
            console.log(data);
          });
          // throw new Error(response);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error signing up: ", error);
        setError(`Error signing up.${error}`);
      });
  };
  if (loading) {
    return (
      <ColorRing
        visible={true}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="500"
        width="500"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
      />
    );
  }

  if (isSignedUp) {
    return <CongratulatoryMessage show={isSignedUp} />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Create your account
          </h2>
          {/* Display error message if it exists */}
          {error && (
            <p className="mt-2 text-center text-sm text-red-600">{error}</p>
          )}
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSignUp}>
          <div className="rounded-md shadow-sm">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <label htmlFor="lastName" className="sr-only">
                Prenom
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                autoComplete="lastName"
                required
                className="appearance-none rounded-none relative block w-full my-2 me-4 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Prenom"
                value={lastName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <label htmlFor="firstName" className="sr-only">
                Nom
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                autoComplete="firstName"
                required
                className="appearance-none rounded-none relative block w-full my-2 ms-3 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Nom"
                value={firstName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full my-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="sr-only">
                phone Number
              </label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="tel"
                autoComplete="phoneNumber"
                required
                className="appearance-none rounded-none relative block w-full my-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Numero de Telephone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="companyName" className="sr-only">
                Company Name
              </label>
              <input
                id="companyName"
                name="companyName"
                type="text"
                autoComplete="companyName"
                required
                className="appearance-none rounded-none relative block w-full my-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Nom de l'Entreprise"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="companyAddress" className="sr-only">
                Address
              </label>
              <input
                id="companyAddress"
                name="companyAddress"
                type="text"
                autoComplete="companyAddress"
                required
                className="appearance-none rounded-none relative block w-full my-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Addresse"
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="companyCity" className="sr-only">
                companyCity
              </label>
              <input
                id="companyCity"
                name="companyCity"
                type="text"
                autoComplete="companyCity"
                required
                className="appearance-none rounded-none relative block w-full my-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Ville"
                value={companyCity}
                onChange={(e) => setCompanyCity(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="companyCountry" className="sr-only">
                Pays
              </label>
              <select
                id="companyCountry"
                name="companyCountry"
                type="text"
                autoComplete="companyCountry"
                required
                className="appearance-none rounded-none relative block w-full my-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Pays"
                value={companyCountry}
                onChange={(e) => handleCountryChange(e.target.value)}
              >
                <option value="">Select Country</option>
                {Country.getAllCountries().map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="password" className="sr-only">
                Mots de Pass
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                className="appearance-none rounded-none relative block w-full my-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Mots de Pass"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="confirmPassword" className="sr-only">
                Confirmez Mots de Pass
              </label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                required
                className="appearance-none rounded-none relative block w-full my-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Confirmez Mots de Pass"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            {passwordMatchMessage && (
              <p style={{ color: "red" }}>{passwordMatchMessage}</p>
            )}
            <div>
              <label htmlFor="" className="sr-only">
                reference
              </label>
              <input
                id="reference"
                name="reference"
                type="text"
                autoComplete="reference"
                className="appearance-none rounded-none relative block w-full my-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Code de reference"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={passwordMatchMessage ? true : false}
            >
              Sign up
            </button>
          </div>
        </form>
        <CongratulatoryMessage show={isSignedUp} />
      </div>
    </div>
  );
}

export default SignUp;
