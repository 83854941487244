import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { AsYouType } from "libphonenumber-js";
import { ColorRing } from "react-loader-spinner";
import { db, analytics } from "../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import {displaySanitizedInput} from "../utils/inputSanitizer"
// import DialogflowMessenger from "../components/DialogflowMessenger";

const fileType = [
  "txt",
  "pdf",
  "doc",
  "docx",
  ".abw",
  "application/octet-stream",
  "application/pdf",
  "application/x-abiword",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
const fileSizeLimit = 400001;
const jobDocRef = doc(db, `job`, "5tdpJAv6JNwmawkDKPSH");
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const ApplicationsPage = () => {
  const navigate = useNavigate();
  const [showChatbot, setShowChatbot] = useState(false);
  const { jobID } = useParams();
  const [tenantID, setTenantID] = useState("");
  const [jobStatus, setJobStatus] = useState("");
  const [jobUuid, setJobUuid] = useState("");
  const [jobDetails, setJobDetails] = useState({});
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [certificatesDocument, setCertificatesDocument] = useState(null);
  const [diplomas, setDiplomas] = useState(null);
  const [selectedCountryisoCode, setSelectedCountryisoCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [educations, setEducations] = useState([
    { Institution: "", Degree: "", FieldOfStudy: "", GraduationYear: "" },
  ]);
  const [references, setReferences] = useState([
    { RefereeName: "", Relation: "", ContactInformation: "" },
  ]);
  const [certificates, setCertificates] = useState([
    {
      CertificationName: "",
      IssuingOrganization: "",
      DateObtained: "",
      ExpiryDate: "",
    },
  ]);
  const [languages, setLanguages] = useState([
    { Language: "", Proficiency: "" },
  ]);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [educationErrors, setEducationErrors] = useState([
    {
      Institution: false,
      Degree: false,
      FieldOfStudy: false,
      GraduationYear: false,
    },
  ]);
  const [fullName, setFullName] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [skills, setSkills] = useState("");
  const [sex, setSex] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [resumeError, setResumeError] = useState("");
  const [coverLetterError, setCoverLetterError] = useState("");
  const [certificateError, setCertificateError] = useState("");
  const [diplomaError, setDiplomaError] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [certificateName, setCertificateName] = useState("");
  const [coverLetterName, setCoverLetterName] = useState("");
  const [diplomaName, setDiplomaName] = useState("");
  const [resumeUploadError, setResumeUploadError] = useState(false);
  const [dateLimiteDeCandidature, setDateLimiteDeCandidature] = useState(0);
  const [jobNotFound, setJobNotFound] = useState(false);
  const [thankYouPageObject, setThankYouPageObject] = useState({});

  // Fetch job details
  useEffect(() => {
    // console.log(`Starting ${jobID}`);
    try {
      // console.log(jobDocRef);
      // const docRef = doc(db,`job`,"5tdpJAv6JNwmawkDKPSH");
      getDoc(jobDocRef)
        .catch((error) => {
          console.error(error);
          setJobNotFound(true);
        })
        .then((jobDoc) => {
          if (jobDoc.get(jobID) != null) {
            getDoc(jobDoc.get(jobID))
              .catch((error) => console.error(error))
              .then((data) => {
                setTenantID(data.get("tenantId"));
                setJobUuid(data.get("uuid"));
                setJobStatus(data.get("Statut"));
                logEvent(analytics, "VisitedApplicationPage", {
                  tenantID: data.get("tenantId"),
                  jobUUID: data.get("uuid"),
                  jobID: jobID,
                });
                setJobDetails({
                  Titre: displaySanitizedInput(data.get("Titre")),
                  DateLimiteDeCandidature: data.get("DateLimiteDeCandidature"),
                  Description: displaySanitizedInput(data.get("Description")),
                  companyName: data.get("Entreprise"),
                  type: data.get("type"),
                  
                });
                setDateLimiteDeCandidature(
                  new Date(data.get("DateLimiteDeCandidature")).getTime()
                );
              });
          } else {
            setJobNotFound(true);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }, [jobID, tenantID]);

  useEffect(() => {
    setThankYouPageObject({
      tenantId: tenantID,
      jobId: jobUuid,
      fullName: fullName,
      email: email,
      phoneNumber: phoneNumber,
      referrer: "application",
    });
  }, [tenantID, jobUuid, fullName, email, phoneNumber]);

  // Handle file upload
  const handleFileUpload = (file, location) => {
    var filename = "";
    switch (location) {
      case "resumesactive":
        filename = resumeName;
        break;
      case "certificationsactive":
        filename = certificateName;
        break;
      case "diplomasactive":
        filename = diplomaName;
        break;
      case "coverletteractive":
        filename = coverLetterName;
        break;
      default:
        throw new Error(`No location provided for file upload.`);
        break;
    }
    const formData = new FormData();
    formData.append("file", file);
    return fetch(
      `${API_ENDPOINT}/api/v1/storages/uploads/${location}/name/${filename}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error(`handleFileUpload error: ${error}`);
        setResumeUploadError(true);
        /* alert(
          `Une erreur s'est produite en téléchargeant votre document. Veuillez réessayer.`
        ); */
        throw error;
      });
  };

  // Handle country change
  const handleCountryChange = (event) => {
    event.preventDefault();
    const isoCode = event.target.value;
    setSelectedCountryisoCode(isoCode);
    setSelectedCountry(Country.getCountryByCode(isoCode));
    setSelectedState("");
    setSelectedCity("");
  };

  // Handle phone number change
  const handlePhoneNumberChange = (event) => {
    event.preventDefault();
    const asYouType = new AsYouType(selectedCountry);
    setPhoneNumber(asYouType.input(event.target.value));
  };

  // Handle file change
  const handleFileChange = (event) => {
    event.preventDefault();
    const { name, files } = event.target;
    const file = files[0];
    if (!fileType.includes(file.type) || file.size > fileSizeLimit) {
      const error = fileType.includes(file.type)
        ? `Veuillez télécharger un document moins de ${
            fileSizeLimit / 1000
          }KB. Votre document est: ${file.size / 1000}KB.`
        : `Veuillez télécharger un document au format Word ou PDF. Votre document est: ${file.type}.`;
      if (name === "resume") setResumeError(error);
      if (name === "coverLetter") setCoverLetterError(error);
      if (name === "certificates") setCertificateError(error);
      if (name === "diplomas") setDiplomaError(error);
      console.log(error);
      alert(error);
    } else {
      var filename = "unknown";
      var d = new Date().toUTCString();
      var fn = `${`${fullName.replace(/\s/g, "")}`}_${d}`;
      fn = fn.replaceAll(" ", "_");
      fn = fn.replaceAll(",", "");
      fn = fn.replaceAll(":", "_");
      switch (name) {
        case "resume":
          filename = `Resume_${fn}`;
          setResumeName(filename);
          break;
        case "certificatesDocument":
          filename = `Certificate_${fn}`;
          setCertificateName(filename);
          break;
        case "diplomas":
          filename = `Diploma_${fn}`;
          setDiplomaName(filename);
          break;
        case "coverLetter":
          filename = `CoverLetter_${fn}`;
          setCoverLetterName(filename);
          break;
        default:
          break;
      }
      if (name === "resume") setResume(file);
      if (name === "coverLetter") setCoverLetter(file);
      if (name === "certificatesDocument") setCertificatesDocument(file);
      if (name === "diplomas") setDiplomas(file);
      if (name === "resume") setResumeError("");
      if (name === "coverLetter") setCoverLetterError("");
      if (name === "certificates") setCertificateError("");
      if (name === "diplomas") setDiplomaError("");
    }
  };

  // Add or remove items in arrays
  const addOrRemoveItem = (array, setArray, item = {}, index) => {
    if (item) {
      setArray([...array, item]);
    } else {
      const newArray = [...array];
      newArray.splice(index, 1);
      setArray(newArray);
    }
  };
  // Add or remove items in arrays
  const addOrRemoveText = (text, setText) => {
    setText(text);
  };
  // Handle input change for arrays
  const handleArrayInputChange = (array, setArray, index, event) => {
    event.preventDefault();
    const newArray = [...array];
    newArray[index][event.target.name] = event.target.value;
    setArray(newArray);
  };
  const generateShortCode = () => {
    var applicationUrlId = Math.random().toString(36).substring(2, 8);
    /* // Check if the field exists. if it does regenerate a new one
    if (applicationUrlIdDoc[applicationUrlId]) {
      // Field does not exist, create it with a value
      return generateShortCode(applicationUrlIdDoc);
    } */
    return applicationUrlId;
  };
  // Handle form submission
  const handleSubmit = async (event) => {
    setDisableSubmitButton(true);
    setLoading(true);
    console.log(`hit submit ${disableSubmitButton}`);
    event.preventDefault();
    setResumeUploadError(false);
    setSubmissionAttempted(true);

    const newEducationErrors = educations.map((edu) => ({
      Institution: !edu.Institution,
      Degree: !edu.Degree,
      FieldOfStudy: !edu.FieldOfStudy,
      GraduationYear: !edu.GraduationYear,
    }));

    setEducationErrors(newEducationErrors);

    const isValidEducation = educations.some(
      (edu) =>
        edu.Institution && edu.Degree && edu.FieldOfStudy && edu.GraduationYear
    );
    const isValid = !newEducationErrors.some((err) =>
      Object.values(err).includes(true)
    );

    if (!isValid || !isValidEducation || !resume || resumeError) {
      const errorMessage = !isValidEducation
        ? "Veuillez remplir tous les champs requis de l'éducation."
        : !resume
        ? "Veuillez télécharger votre CV."
        : resumeError
        ? resumeError
        : "Veuillez corriger les erreurs avant de soumettre.";
      alert(errorMessage);
      setDisableSubmitButton(false);
      setLoading(false);
      return;
    }

    const uploadPromises = [];
    if (certificatesDocument)
      uploadPromises.push(
        handleFileUpload(certificatesDocument, "certificationsactive")
      );
    if (diplomas)
      uploadPromises.push(handleFileUpload(diplomas, "diplomasactive"));
    if (coverLetter)
      uploadPromises.push(handleFileUpload(coverLetter, "coverletteractive"));
    uploadPromises.push(handleFileUpload(resume, "resumesactive"));

    Promise.all(uploadPromises)
      .then(() => {
        const jobPosting = {
          ApplicantID: generateShortCode(),
          type: jobDetails.type,
          FullName: fullName,
          Sex: sex,
          Score: null,
          ScoreReason: "",
          ContactInformation: {
            Email: email,
            Phone: phoneNumber,
            Address: address,
            City: selectedCity,
            State: selectedState,
            Country: selectedCountry,
          },
          PositionAppliedFor: jobDetails.Titre,
          companyName: jobDetails.companyName,
          ApplicationDate: new Date(Date.now()).toISOString(),
          Diploma: diplomaName,
          Resume: resumeName,
          CoverLetter: coverLetterName,
          Education: educations,
          qualifications: qualifications,
          Skills: skills,
          References: references,
          Portfolio: "",
          Certifications: certificates,
          CertificationDocument: certificateName,
          Languages: languages,
          ApplicationStatus: "Soumise",
        };

        if (!resumeUploadError) {
          fetch(
            `${API_ENDPOINT}/api/v1/tenants/${tenantID}/jobpostings/${jobUuid}/applications`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(jobPosting),
            }
          )
            .then((r) => {
              logEvent(analytics, "appliedToJob", {
                tenantID: tenantID,
                jobUUID: jobUuid,
                jobID: jobID,
              });
              navigate("/thank-you", {
                state: thankYouPageObject,
                replace: true,
              });
            })
            .catch((error) => {
              console.error(error);
              setDisableSubmitButton(false);
              setLoading(false);
              // alert(error);
              alert(
                `Une erreur s'est produite lors de l'enregistrement de votre candidature`
              );
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setDisableSubmitButton(false);
        setLoading(false);
        alert(
          `Une erreur s'est produite en téléchargeant votre document. Veuillez réessayer.`
        );
        // alert(error);
      });
  };
  if (jobNotFound) {
    return (
      <body style={{ backgroundColor: "african-sand" }}>
        <div className="bg-african-sand p-6 rounded-lg shadow-md">
          <h1>404 - Not Found</h1>
          <p>
            L'offre d'emploi que vous recherchez est introuvable. Veuillez
            vérifier l'identifiant correct de l'offre d'emploi.
          </p>
        </div>
      </body>
    );
  } else if (jobStatus === "Completer") {
    return (
      <body style={{ backgroundColor: "bg-african-sand" }}>
        <div className="bg-african-sand p-6 rounded-lg shadow-md">
          <h1>Fermée</h1>
          <p>Cette offre d'emploi est fermée. Merci.</p>
        </div>
      </body>
    );
  } else if (jobStatus === "Pause") {
    return (
      <body style={{ backgroundColor: "african-sand" }}>
        <div>
          <h1>Pause</h1>
          <p>
            Cette offre d'emploi est en pause pour le moment. Veuillez revenir
            plus tard. Merci.
          </p>
        </div>
      </body>
    );
  } else if (jobStatus === "Annuler") {
    return (
      <body style={{ backgroundColor: "bg-african-sand" }}>
        <div className="bg-african-sand p-6 rounded-lg shadow-md">
          <h1>Annuler</h1>
          <p>Cette offre d'emploi est annulée. Merci.</p>
        </div>
      </body>
    );
  } else if (dateLimiteDeCandidature === 0 || loading) {
    return (
      <ColorRing
        visible={true}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="500"
        width="500"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
      />
    );
  } else if (dateLimiteDeCandidature < new Date().getTime()) {
    return (
      <div className="bg-african-sand p-6 rounded-lg shadow-md">
        Désolé, la date limite pour cette offre d'emploi est dépassée.
      </div>
    );
  } else {
    // Render form
    return (
      <div className="bg-african-sand p-6 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-4">
          Candidature au poste de {jobDetails.Titre || "Job"}
        </h1>
        <h2 className="text-xl  mb-4">
          <div
            className="content"
            style={{
              padding: "0 18px",
              height: "100px",
              overflow: "scroll",
              backgroundColor: "lightgoldenrodyellow",
            }}
          >
            <p>
              <strong>Description du poste:</strong> {jobDetails.Description}
            </p>
          </div>
        </h2>
        <form onSubmit={(e) => handleSubmit(e)} className="space-y-4">
          <input type="hidden" name="JobID" value={jobID} readOnly />
          <div>
            <label
              htmlFor="fullName"
              className="block text-xl font-semibold text-black-700"
            >
              Nom et Prénom{" "}
              <span style={{ color: "red", font: "small-caption" }}>
                *requis*
              </span>
            </label>
            <input
              name="FullName"
              id="fullName"
              type="text"
              autoComplete="name"
              value={fullName}
              onChange={(e) => addOrRemoveText(e.target.value, setFullName)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            />
          </div>
          <div>
            <label className="block text-xl font-semibold text-black-700">
              Sexe{" "}
              <span style={{ color: "red", font: "small-caption" }}>
                *requis*
              </span>
            </label>

            <select
              htmlFor="Sex"
              id="Sex"
              name="Sex"
              autoComplete="name"
              value={sex}
              onChange={(e) => addOrRemoveText(e.target.value, setSex)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            >
              <option value="">Chosir le Sexe</option>
              <option value="Masculin">Masculin</option>
              <option value="Feminin">Feminin</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-xl font-semibold text-black-700"
            >
              Email{" "}
              <span style={{ color: "red", font: "small-caption" }}>
                *requis*
              </span>
            </label>
            <input
              name="Email"
              id="email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={(e) => addOrRemoveText(e.target.value, setEmail)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            />
          </div>

          <div>
            <label
              htmlFor="address"
              className="block text-xl font-semibold text-black-700"
            >
              Addresse
            </label>
            <input
              name="Address"
              id="address"
              type="text"
              autoComplete="street-address"
              value={address}
              onChange={(e) => addOrRemoveText(e.target.value, setAddress)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            />
          </div>

          <div>
            <label className="block text-xl font-semibold text-black-700">
              Pays{" "}
              <span style={{ color: "red", font: "small-caption" }}>
                *requis*
              </span>
            </label>
            <select
              name="country"
              autoComplete="country"
              onChange={(e) => handleCountryChange(e)}
              required
              value={selectedCountryisoCode}
            >
              <option value="">Select Country</option>
              {Country.getAllCountries().map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          {
            <div>
              <label className="block text-xl font-semibold text-black-700">
                State/Region
              </label>
              <select
                name="state"
                autoComplete="address-level1"
                onChange={(e) =>
                  addOrRemoveText(e.target.value, setSelectedState)
                }
                value={selectedState}
              >
                <option value="">Select State</option>
                {State.getStatesOfCountry(selectedCountryisoCode).map(
                  (state) => (
                    <option key={state.name} value={state.name}>
                      {state.name}
                    </option>
                  )
                )}
              </select>
            </div>
          }
          <div>
            <label className="block text-xl font-semibold text-black-700">
              Ville{" "}
              <span style={{ color: "red", font: "small-caption" }}>
                *requis*
              </span>
            </label>
            <select
              name="city"
              autoComplete="address-level2"
              onChange={(e) => addOrRemoveText(e.target.value, setSelectedCity)}
              value={selectedCity}
            >
              <option value="">Choisi la ville</option>
              {City.getCitiesOfCountry(selectedCountryisoCode).map((city) => (
                <option key={city.name} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-xl font-semibold text-black-700">
              Numero de Telephone{" "}
              <span style={{ color: "red", font: "small-caption" }}>
                *requis*
              </span>
            </label>
            <input
              name="telephone"
              id="telephone"
              type="tel"
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(e)}
              placeholder="Enter phone number"
              autoComplete="tel"
            />
          </div>

          <div>
            <label
              htmlFor="resume"
              className={
                resumeError !== ""
                  ? "block text-xl font-bold text-red-700"
                  : "block text-xl font-semibold text-black-700"
              }
            >
              CV{" "}
              <span style={{ color: "red", font: "small-caption" }}>
                *requis*
              </span>{" "}
              {resumeError}
            </label>
            <input
              name="resume"
              id="resume"
              type="file"
              accept=".txt,.pdf,.doc,.docx,.odt,.abw,application/octet-stream,application/pdf,application/vnd.oasis.opendocument.text,application/x-abiword,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              max-size="400000"
              required
              className={
                resumeError !== ""
                  ? "border-red-500"
                  : "mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              }
              onChange={(e) => handleFileChange(e)}
            />
          </div>

          <div>
            <label
              htmlFor="coverLetter"
              className={
                coverLetterError !== ""
                  ? "block text-xl font-bold text-red-700"
                  : "block text-xl font-semibold text-black-700"
              }
            >
              Lettre de Motivation {coverLetterError}
            </label>
            <input
              name="coverLetter"
              id="coverLetter"
              type="file"
              accept=".txt,.pdf,.doc,.docx,.odt,.abw,application/octet-stream,application/pdf,application/vnd.oasis.opendocument.text,application/x-abiword,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              max-size="400000"
              className={
                coverLetterError !== ""
                  ? "border-red-500"
                  : "mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              }
              onChange={(e) => handleFileChange(e)}
            />
          </div>
          {/* Education section */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold mb-4">
              Education{" "}
              <span style={{ color: "red", font: "small-caption" }}>
                *requis*
              </span>
            </h2>
            {educations.map((edu, index) => (
              <div
                key={index}
                className="grid grid-cols-1 md:grid-cols-2 gap-4"
              >
                <input
                  className={`input ${
                    submissionAttempted && educationErrors[index].Institution
                      ? "border-red-500"
                      : ""
                  }`}
                  placeholder="Institution"
                  type="text"
                  name={`Institution`}
                  value={edu.Institution}
                  onChange={(e) =>
                    handleArrayInputChange(educations, setEducations, index, e)
                  }
                />
                <input
                  className={`input ${
                    submissionAttempted && educationErrors[index].Degree
                      ? "border-red-500"
                      : ""
                  }`}
                  placeholder="diplome"
                  type="text"
                  name={`Degree`}
                  value={edu.Degree}
                  onChange={(e) =>
                    handleArrayInputChange(educations, setEducations, index, e)
                  }
                />
                <input
                  className={`input ${
                    submissionAttempted && educationErrors[index].FieldOfStudy
                      ? "border-red-500"
                      : ""
                  }`}
                  placeholder="domaine d'études"
                  type="text"
                  name={`FieldOfStudy`}
                  value={edu.FieldOfStudy}
                  onChange={(e) =>
                    handleArrayInputChange(educations, setEducations, index, e)
                  }
                />
                <input
                  className={`input ${
                    submissionAttempted && educationErrors[index].GraduationYear
                      ? "border-red-500"
                      : ""
                  }`}
                  placeholder="Année d'obtention du diplôme"
                  type="month"
                  name={`GraduationYear`}
                  value={edu.GraduationYear}
                  onChange={(e) =>
                    handleArrayInputChange(educations, setEducations, index, e)
                  }
                />
              </div>
            ))}
            <button
              type="button"
              onClick={(e) =>
                addOrRemoveItem(
                  educations,
                  setEducations,
                  {
                    Institution: "",
                    Degree: "",
                    FieldOfStudy: "",
                    GraduationYear: "",
                  },
                  educations.length
                )
              }
              className="mt-2 text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Ajouter une autre éducation
            </button>
            <button
              type="button"
              onClick={() =>
                addOrRemoveItem(
                  educations,
                  setEducations,
                  null,
                  educations.length - 1
                )
              }
              disabled={educations.length < 2 ? true : false}
              className="col-span-2 md:col-span-1 text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2"
            >
              Retirer une education
            </button>
          </div>

          <div>
            <h2
              htmlFor="diplomas"
              className={
                diplomaError !== ""
                  ? "text-xl font-bold mb-4 text-red-700"
                  : "text-xl font-semibold mb-4 text-black-700"
              }
            >
              Diplome {diplomaError}
            </h2>
            <input
              type="file"
              accept=".txt,.pdf,.doc,.docx,.odt,.abw,application/octet-stream,application/pdf,application/vnd.oasis.opendocument.text,application/x-abiword,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              max-size="400000"
              id="diplomas"
              name="diplomas"
              onChange={(e) => handleFileChange(e)}
            />
          </div>

          {/* references section */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold mb-4">References</h2>
            {references.map((reference, index) => (
              <div
                key={index}
                className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4"
              >
                <input
                  className="input"
                  name="RefereeName"
                  placeholder="nom de la personne de référence"
                  type="text"
                  value={reference.RefereeName}
                  onChange={(e) =>
                    handleArrayInputChange(references, setReferences, index, e)
                  }
                />
                <input
                  className="input"
                  name="Relation"
                  placeholder="Relation avec la personne de référence"
                  type="text"
                  value={reference.Relation}
                  onChange={(e) =>
                    handleArrayInputChange(references, setReferences, index, e)
                  }
                />
                <input
                  className="input"
                  name="ContactInformation"
                  placeholder="Contact Information"
                  type="text"
                  value={reference.ContactInformation}
                  onChange={(e) =>
                    handleArrayInputChange(references, setReferences, index, e)
                  }
                />
              </div>
            ))}
            <button
              type="button"
              onClick={(e) =>
                addOrRemoveItem(
                  references,
                  setReferences,
                  { RefereeName: "", Relation: "", ContactInformation: "" },
                  references.length
                )
              }
              className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Ajouter une reference
            </button>
            <button
              type="button"
              onClick={() =>
                addOrRemoveItem(
                  references,
                  setReferences,
                  null,
                  references.length - 1
                )
              }
              className="col-span-3 md:col-span-1 text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2"
            >
              Effacer une reference
            </button>
          </div>

          {/* certifications section */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold mb-4">Certifications</h2>
            {certificates.map((cert, index) => (
              <div
                key={index}
                className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4"
              >
                <input
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  name="CertificationName"
                  placeholder="Certification Name"
                  type="text"
                  value={cert.CertificationName}
                  onChange={(e) =>
                    handleArrayInputChange(
                      certificates,
                      setCertificates,
                      index,
                      e
                    )
                  }
                />
                <input
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  name="IssuingOrganization"
                  placeholder="Issuing Organization"
                  type="text"
                  value={cert.IssuingOrganization}
                  onChange={(e) =>
                    handleArrayInputChange(
                      certificates,
                      setCertificates,
                      index,
                      e
                    )
                  }
                />
                <input
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  name="DateObtained"
                  placeholder="Date Obtained (YYYY-MM-DD)"
                  type="date"
                  value={cert.DateObtained}
                  onChange={(e) =>
                    handleArrayInputChange(
                      certificates,
                      setCertificates,
                      index,
                      e
                    )
                  }
                />
                <input
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  name="ExpiryDate"
                  placeholder="Expiry Date (YYYY-MM-DD)"
                  type="date"
                  value={cert.ExpiryDate}
                  onChange={(e) =>
                    handleArrayInputChange(
                      certificates,
                      setCertificates,
                      index,
                      e
                    )
                  }
                />
              </div>
            ))}
            <button
              type="button"
              onClick={(e) =>
                addOrRemoveItem(
                  certificates,
                  setCertificates,
                  {
                    CertificationName: "",
                    IssuingOrganization: "",
                    DateObtained: "",
                    ExpiryDate: "",
                  },
                  certificates.length
                )
              }
              className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Ajouter une autre certification
            </button>
            <button
              type="button"
              onClick={(e) =>
                addOrRemoveItem(
                  certificates,
                  setCertificates,
                  null,
                  certificates.length - 1
                )
              }
              className="col-span-2 md:col-span-1 text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2"
            >
              retirer une certification
            </button>
          </div>

          <div>
            <label
              htmlFor="certificatesDocument"
              className={
                certificateError !== ""
                  ? "text-xl font-bold mb-4 text-red-700"
                  : "text-xl font-semibold mb-4 text-black-700"
              }
            >
              Certificats {certificateError}
            </label>
            <input
              type="file"
              accept=".txt,.pdf,.doc,.docx,.odt,.abw,application/octet-stream,application/pdf,application/vnd.oasis.opendocument.text,application/x-abiword,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              max-size="400000"
              id="certificatesDocument"
              name="certificatesDocument"
              onChange={(e) => handleFileChange(e)}
            />
          </div>

          {/* Education section */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold mb-4">Languages</h2>
            {languages.map((language, index) => (
              <div
                key={index}
                className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end"
              >
                <input
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  name="Language"
                  placeholder="Language"
                  type="text"
                  value={language.Language}
                  onChange={(e) =>
                    handleArrayInputChange(languages, setLanguages, index, e)
                  }
                />
                <select
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  name="Proficiency"
                  value={language.Proficiency}
                  onChange={(e) =>
                    handleArrayInputChange(languages, setLanguages, index, e)
                  }
                >
                  <option value="">Select Proficiency</option>
                  <option value="Basic">Basic</option>
                  <option value="Conversational">Conversational</option>
                  <option value="Fluent">Fluent</option>
                  <option value="Native">Native</option>
                </select>
              </div>
            ))}
            <button
              type="button"
              onClick={(e) =>
                addOrRemoveItem(
                  languages,
                  setLanguages,
                  { Language: "", Proficiency: "" },
                  languages.length
                )
              }
              className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Ajouter une autre langue
            </button>
            <button
              type="button"
              onClick={(e) =>
                addOrRemoveItem(
                  languages,
                  setLanguages,
                  null,
                  languages.length - 1
                )
              }
              className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Supprimer une langue
            </button>
          </div>

          <div>
            <label
              htmlFor="qualifications"
              className="block text-xl font-medium text-black-700"
            >
              Qualifications
            </label>
            <textarea
              name="Qualifications"
              id="qualifications"
              rows="4"
              value={qualifications}
              onChange={(e) =>
                addOrRemoveText(e.target.value, setQualifications)
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            ></textarea>
          </div>

          <div>
            <label
              htmlFor="skills"
              className="block text-xl font-medium text-black-700"
            >
              Skills
            </label>
            <input
              name="Skills"
              id="skills"
              type="text"
              value={skills}
              onChange={(e) => addOrRemoveText(e.target.value, setSkills)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              placeholder="Comma-separated, e.g., HTML, CSS, JavaScript"
            />
          </div>

          {/* Add more fields as necessary for additional data you wish to collect */}

          <button
            type="submit"
            disabled={disableSubmitButton}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            Submit Application
          </button>
        </form>
        {/* <div>
          {showChatbot ? (
            <DialogflowMessenger userId={""} userParams={{}} />
          ) : (
            ""
          )}
        </div> */}
      </div>
    );
  }
};

export default ApplicationsPage;
