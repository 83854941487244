// ../utils/inputSanitizer.js

/**
 * Sanitizes user input to prevent XSS vulnerabilities.
 * Handles strings, arrays, and JSON objects.
 *
 * @param {string|object|Array} input - The user input string, array, or JSON object to sanitize.
 * @returns {string|object|Array} The sanitized input string, array, or JSON object.
 */
export const sanitizeInput = (input) => {
  if (typeof input === "string") {
    return input
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#x27;");
  } else if (Array.isArray(input)) {
    return input.map(sanitizeInput);
  } else if (typeof input === "object" && input !== null) {
    return JSON.parse(
      JSON.stringify(input, (key, value) => 
        typeof value === "string" ? sanitizeInput(value) : value
      )
    );
  } else {
    return input; // Handle non-string, non-object, and non-array input (e.g., return an empty string)
  }
};

/**
 * Reverts sanitized input back to its original form for display.
 * Handles strings, arrays, and JSON objects.
 *
 * @param {string|object|Array} input - The sanitized input string, array, or JSON object to revert.
 * @returns {string|object|Array} The original input string, array, or JSON object.
 */
export const displaySanitizedInput = (input) => {
  if (typeof input === "string") {
    return input
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#x27;/g, "'");
  } else if (Array.isArray(input)) {
    return input.map(displaySanitizedInput);
  } else if (typeof input === "object" && input !== null) {
    return JSON.parse(
      JSON.stringify(input, (key, value) =>
        typeof value === "string" ? displaySanitizedInput(value) : value
      )
    );
  } else {
    return input; // Handle non-string, non-object, and non-array input (e.g., return an empty string)
  }
};

// Default export
export default displaySanitizedInput;
