import React, { useState } from "react";
import Modal from "./Modal";
import { useSelector, useDispatch } from "react-redux";
import { updateCandidateStatus } from "../utils/redux/candidateActions";

const CandidateStatusUpdateModal = ({ isOpen, onClose, candidate, tenantId, jobUuid  }) => {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState("");

  // const { tenantId, jobUuid } = useSelector((state) => state);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newStatus = e.target.status.value;

    if (candidate) {
      let confirmationMessage = `⚠️Etes-vous sûr de changer le statut de ${candidate.ApplicationStatus} en ${newStatus}⚠️`;
      if (newStatus === "Rejeter") {
        confirmationMessage =
          "❗⚠️❗ Etes-vous sûr de vouloir rejeter cette candidature ? Le candidat va recevoir un email de rejet ❗⚠️❗ ";
      }

      const answer = window.confirm(confirmationMessage);
      if (answer) {
        try {
          // Dispatch the updateCandidateStatus action
          dispatch(
            updateCandidateStatus(candidate, newStatus, tenantId, jobUuid)
          );
          onClose(); // Close the modal
        } catch (error) {
          console.error("Error updating candidate status:", error);
          // Handle the error (e.g., show an error message to the user)
        }
      }
    }

    setSelectedStatus("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Changer Le Statut Du Candidat"
    >
      <form onSubmit={handleSubmit}>
        <select
          name="status"
          className="border-gray-300 rounded px-3 py-2"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="">Choisir Status</option>
          <option value="Submission">Soumission de la candidature</option>
          <option value="Evaluation">Soumission des tests d'evaluation</option>
          <option value="Interview">Entretien</option>
          <option value="Rejeter">Rejeter</option>
          <option value="Offer">Offre d'emploi</option>
          <option value="Accepted">Offre Acceptée</option>
          {/* Add other statuses as needed */}
        </select>

        {selectedStatus && (
          <p className="text-red-500 mt-2">
            ⚠️ Status: {selectedStatus}. Veuillez confirmer.
          </p>
        )}

        <button
          type="submit"
          className="ml-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 mt-4"
        >
          Save
        </button>
      </form>
    </Modal>
  );
};

export default CandidateStatusUpdateModal;
