import React, { useEffect } from "react";

const TawkToChat = () => {
  useEffect(() => {
    const tawkScript = document.createElement("script");
    tawkScript.async = true;
    tawkScript.src = "https://embed.tawk.to/667ef7e89d7f358570d4997f/1i1fvgpcl";
    tawkScript.charset = "UTF-8";
    tawkScript.setAttribute("crossorigin", "*");

    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tawkScript, firstScriptTag);

    // Cleanup: Remove script when component unmounts (optional)
    return () => {
      firstScriptTag.parentNode.removeChild(tawkScript);
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  // You can optionally render a placeholder or loading indicator here
  // while the script is loading, if necessary.
  return null; // The chat widget will be injected into the DOM by the script
};

export default TawkToChat;
