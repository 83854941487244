import React, { useState, useEffect, useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import initiatePaydunyaCheckout from "../components/initiatePaydunyaCheckout";
import { signOut } from "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { auth, analytics, db } from "../firebase-config";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import Paydunya from "paydunya";
import TawkToChat from "../components/TawkToChat";
import Navbar from "../components/Navbar";
import { useAuth } from "../useAuth";
import { fetchAndSetCompanyData, selectAllCompanys } from "../utils/redux/companySlice";
import { selectAllUsers, fetchAndSetUserData } from "../utils/redux/userDataSlice";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const calculateTotal = (basicLicenses, proLicenses) => {
  const basicPrice = 10000;
  const proPrice = 80000;
  return basicLicenses * basicPrice + proLicenses * proPrice;
};

const CheckoutForm = ({ handlePaymentSuccess, basicLicenses, proLicenses }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
      return;
    }

    const response = await fetch(`/api/createPaymentIntent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentMethodId: paymentMethod.id,
        amount: calculateTotal(basicLicenses, proLicenses),
      }),
    });

    const paymentResult = await response.json();

    if (paymentResult.error) {
      setError(paymentResult.error.message);
      setProcessing(false);
      return;
    }

    const { clientSecret } = paymentResult;
    console.log(paymentResult)
    console.log(clientSecret)
    console.log(paymentMethod)

    const { error: confirmError } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod.id,
    });

    if (confirmError) {
      setError(confirmError.message);
      setProcessing(false);
      return;
    }

    handlePaymentSuccess();
    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <CardElement className="p-3 border rounded-md" />
      {error && <div className="text-red-500">{error}</div>}
      <button
        disabled={!stripe || processing}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        {processing ? "Processing..." : "Pay"}
      </button>
    </form>
  );
};

const LicenseBuyingPage = () => {
  const [basicLicenses, setBasicLicenses] = useState(0);
  const [proLicenses, setProLicenses] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [message, setMessage] = useState(""); // For PayPal messages
  const companyData = useSelector(selectAllCompanys);
  const { currentUser } = useAuth();
  const [store, setStore] = useState({});
  const [setup, setSetup] = useState({});
  const [paymentStatus, setPaymentStatus] = useState(null); // 'pending', 'completed', 'failed', null
  const [checkingPayment, setCheckingPayment] = useState(false);
  const [hasAnyLicense, setHasAnyLicense] = useState(false);
  const [proLicenseCount, setProLicenseCount] = useState(0);
  const [basicLicenseCount, setBasicLicenseCount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        console.log(currentUser);
        setUserId(analytics, currentUser ? currentUser.uid : "");
        setUserProperties(analytics, {
          fullName: currentUser ? currentUser.displayName : "unknown",
          email: currentUser ? currentUser.email : "unknown",
        });
        const u = await dispatch(fetchAndSetUserData(currentUser.email));
        console.log(u);
        const c = await dispatch(fetchAndSetCompanyData(u.payload.tenantId));
        console.log(c);
        setProLicenseCount(c.payload.proLicense);
        setBasicLicenseCount(c.payload.basicLicense);

        if (c.payload.proLicense + c.payload.basicLicense <= 0) {
          setHasAnyLicense(false);
        } else {
          setHasAnyLicense(true);
        }
      }
    };
    fetchData();
  }, [currentUser, dispatch]);

  useEffect(() => {
    const params = getQueryStringParams();
    if (params.token) {
      var invoice = new Paydunya.CheckoutInvoice(setup, store);
      invoice
        .confirm(params.token)
        .then(function () {
          if (invoice.status.toUpperCase() === "COMPLETED") {
            alert("Payment successful!");
          } else {
            alert("Payment failed. Please try again.");
          }
        })
        .catch(function (e) {
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    if (paymentStatus === "completed") {
      alert("Payment successful!");
    } else if (paymentStatus === "failed") {
      alert("Payment failed. Please try again.");
    }
  }, [paymentStatus]);

  useEffect(() => {
    setSetup(
      new Paydunya.Setup({
        masterKey: process.env.REACT_APP_PAYDUNYA_MASTER_KEY,
        privateKey: process.env.REACT_APP_PAYDUNYA_PRIVATE_KEY,
        publicKey: process.env.REACT_APP_PAYDUNYA_PUBLIC_KEY,
        token: process.env.REACT_APP_PAYDUNYA_TOKEN,
        mode: "test",
      })
    );
    setStore(
      new Paydunya.Store({
        name: "ProRecruit",
        callbackURL: `${process.env.REACT_APP_API_ENDPOINT}/invoice`,
        returnURL: `http://localhost:3000/checkout`,
      })
    );
  }, []);

  // Function to get query string parameters
  const getQueryStringParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return {
      token: urlParams.get("token"),
      payment: urlParams.get("payment"), // Get the payment method
    };
  };

  const handleSignOut = useCallback(async () => {
    try {
      logEvent(analytics, "select_content", {
        content_type: "ClickedSignout",
        content_id: `${currentUser.uid}`,
      });

      await fetch(`${API_ENDPOINT}/api/v1/usersession/sessionLogout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenantId: currentUser.tenantId,
        }),
        credentials: "include",
      });

      await signOut(auth);
    } catch (error) {
      console.error(
        `Sign-out failed. currentUser ${JSON.stringify(currentUser)}, ${error}`
      );
    }
  }, [currentUser]);

  const handleBasicChange = (event) => {
    setBasicLicenses(parseInt(event.target.value) || 0);
  };

  const handleProChange = (event) => {
    setProLicenses(parseInt(event.target.value) || 0);
  };

  const handlePaymentChange = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  const checkPaymentStatus = (token) => {
    let checkCount = 0;
    const maxChecks = 180; // 15 minutes * 60 seconds / 5 seconds interval

    const intervalId = setInterval(async () => {
      try {
        const invoice = new Paydunya.CheckoutInvoice({
          name: "ProRecruit Licenses",
        });

        const confirmResponse = await invoice.confirm(token);

        if (confirmResponse) {
          setPaymentStatus(invoice.status); // Update payment status state
          clearInterval(intervalId); // Stop checking
        }

        checkCount++;
        if (checkCount >= maxChecks) {
          setPaymentStatus("failed"); // Assume failed after 15 minutes
          setCheckingPayment(false);
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
        // Handle error (e.g., display a message to the user)
        setCheckingPayment(false);
        clearInterval(intervalId);
      }
    }, 5000); // Check every 5 seconds
  };

  const handlePaydunyaPayment = async () => {
    try {
      const basicPrice = 10000;
      const proPrice = 80000;
      const totalAmount = basicLicenses * basicPrice + proLicenses * proPrice;

      await initiatePaydunyaCheckout(totalAmount, store, setup);

      const params = getQueryStringParams();
      if (params.token) {
        setCheckingPayment(true); // Set checkingPayment to true before starting the check
        checkPaymentStatus(params.token); // Start checking the payment status
      }
    } catch (error) {
      console.error("Error initiating Paydunya checkout:", error);
      // Handle error (e.g., display a message to the user)
    }
  };

  const handlePaymentSuccess = () => {
    // Handle payment success actions
    setPaymentStatus("completed");
    navigate("/success"); // Redirect to success page
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">Buy Licenses</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-700 font-bold mb-2">
              Basic Licenses:
            </label>
            <input
              type="number"
              value={basicLicenses}
              onChange={handleBasicChange}
              className="border border-gray-400 p-2 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-bold mb-2">
              Pro Licenses:
            </label>
            <input
              type="number"
              value={proLicenses}
              onChange={handleProChange}
              className="border border-gray-400 p-2 rounded w-full"
            />
          </div>
        </div>
        <h3 className="text-xl font-bold mt-6">
          Total: {calculateTotal(basicLicenses, proLicenses)} XOF
        </h3>
        <div className="mt-6">
          <h4 className="text-lg font-bold mb-4">Choose a payment method:</h4>
          <div className="flex flex-wrap gap-4 mb-4">
            <button
              className={`${
                selectedPayment === "card"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              } py-2 px-4 rounded`}
              onClick={() => handlePaymentChange("card")}
            >
              Credit Card
            </button>
            <button
              className={`${
                selectedPayment === "paydunya"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              } py-2 px-4 rounded`}
              onClick={() => handlePaymentChange("paydunya")}
            >
              PayDunya
            </button>
            <button
              className={`${
                selectedPayment === "paypal"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              } py-2 px-4 rounded`}
              onClick={() => handlePaymentChange("paypal")}
            >
              PayPal
            </button>
          </div>
          {selectedPayment === "card" && (
            <Elements stripe={stripePromise}>
              <CheckoutForm
                handlePaymentSuccess={handlePaymentSuccess}
                basicLicenses={basicLicenses}
                proLicenses={proLicenses}
              />
            </Elements>
          )}
          {selectedPayment === "paydunya" && (
            <button
              onClick={handlePaydunyaPayment}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Pay with PayDunya
            </button>
          )}
          {selectedPayment === "paypal" && (
            <PayPalScriptProvider options={{ "client-id": "AfCRB2-04sY9JsO2WP3tDIx-XDDOiZ6l-FIaVoAOa25uaX3NbmVEmp3ysCGTpuVlWAAwrQBeDPPFroTj" }}>
              <PayPalButtons
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: (calculateTotal(basicLicenses, proLicenses) / 650).toFixed(2), // Assuming 650 XOF = 1 USD
                        },
                      },
                    ],
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then((details) => {
                    setMessage("Transaction completed by " + details.payer.name.given_name);
                    handlePaymentSuccess();
                  });
                }}
                onError={(err) => {
                  console.error("PayPal Checkout onError", err);
                  setMessage("An error occurred during the transaction");
                  setPaymentStatus("failed");
                }}
              />
            </PayPalScriptProvider>
          )}
        </div>
        {message && <p className="mt-4 text-lg">{message}</p>}
        <TawkToChat />
      </div>
    </>
  );
};

export default LicenseBuyingPage;
