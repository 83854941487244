// /utils/redux/jobPostingsSlice.js
import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase-config";

const JOBS_PER_PAGE = 20;

export const fetchJobPostings = createAsyncThunk(
  "jobPostings/fetchJobPostings",
  async ({ tenantId }, { getState }) => {
    try {
      console.log(tenantId)
      const state = getState();
      const lastVisible = state.jobPostings.lastVisible;
      let q = query(
        collection(db, `job-descriptions/${tenantId}/active`),
        where("Statut", "!=", "Annuler"),
        orderBy("createdOn", "desc")
      );

      if (lastVisible) {
        q = query(q, startAfter(lastVisible));
      }

      q = query(q, limit(JOBS_PER_PAGE));

      const querySnapshot = await getDocs(q);

      const jobPostingsData = querySnapshot.docs.map((doc) => ({
        uuid: doc.id,
        ...doc.data(),
      }));

      return {
        jobPostings: jobPostingsData,
        lastVisible: jobPostingsData[jobPostingsData.length - 1],
      };
    } catch (error) {
      console.log("Error fetching job postings:", error);
      throw error;
    }
  }
);

export const loadMoreJobPostings = createAsyncThunk(
  "jobPostings/loadMoreJobPostings",
  async ({ tenantId }, { getState }) => {
    try {
      const state = getState();
      const lastVisible = state.jobPostings.lastVisible;
      if (!lastVisible) return;

      let q = query(
        collection(db, `job-descriptions/${tenantId}/active`),
        where("Statut", "!=", "Annuler"),
        orderBy("createdOn", "desc"),
        startAfter(lastVisible),
        limit(JOBS_PER_PAGE)
      );

      const querySnapshot = await getDocs(q);
      const newJobPostings = querySnapshot.docs.map((doc) => ({
        uuid: doc.id,
        ...doc.data(),
      }));

      return {
        jobPostings: newJobPostings,
        lastVisible: newJobPostings[newJobPostings.length - 1],
      };
    } catch (error) {
      console.error("Error loading more job postings:", error);
      throw error;
    }
  }
);

const jobPostingsAdapter = createEntityAdapter({
  selectId: (jobPosting) => jobPosting.uuid,
  sortComparer: (a, b) => b.createdOn.localeCompare(a.createdOn),
});

const initialState = jobPostingsAdapter.getInitialState({
  loading: false,
  error: null,
  lastVisible: null,
  hasMore: true,
});

const jobPostingsSlice = createSlice({
  name: "jobPostings",
  initialState,
  reducers: {
    // ... other reducers (if any) ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobPostings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobPostings.fulfilled, (state, action) => {
        state.loading = false;
        jobPostingsAdapter.setAll(state, action.payload.jobPostings);
        state.lastVisible = action.payload.lastVisible;
        state.hasMore = action.payload.jobPostings.length === JOBS_PER_PAGE;
      })
      .addCase(fetchJobPostings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loadMoreJobPostings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadMoreJobPostings.fulfilled, (state, action) => {
        state.loading = false;
        jobPostingsAdapter.addMany(state, action.payload.jobPostings);
        state.lastVisible = action.payload.lastVisible;
        state.hasMore = action.payload.jobPostings.length === JOBS_PER_PAGE;
      })
      .addCase(loadMoreJobPostings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  selectAll: selectJobPostings,
  selectById: selectJobPostingById,
  selectIds: selectJobPostingIds,
} = jobPostingsAdapter.getSelectors((state) => state.jobPostings);

export const selectJobPostingsLoading = (state) => state.jobPostings.loading;
export const selectJobPostingsError = (state) => state.jobPostings.error;

export default jobPostingsSlice.reducer;
