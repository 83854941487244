// store.js
import { configureStore } from "@reduxjs/toolkit";
import candidateReducer from "./candidateSlice";
import userDataReducer from "./userDataSlice";
import jobPostingsSlice from "./jobPostingsSlice";
import companySlice from "./companySlice";
import assessmentSlice from "./assessmentSlice";

const store = configureStore({
  reducer: {
    candidates: candidateReducer,
    userData: userDataReducer,
    jobPostings: jobPostingsSlice,
    companyData: companySlice,
    assessments: assessmentSlice,
  },
});

export default store;
