// /utils/redux/companySlice.js
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../firebase-config";

const companyAdapter = createEntityAdapter({
  selectId: (companyData) => companyData.tenantId,
});

const initialState = companyAdapter.getInitialState({ loading: false });

export const fetchAndSetCompanyData = createAsyncThunk(
  "companyData/fetchAndSetcompanyData",
  async (tenantId, { rejectWithValue }) => {
    try {
      console.log(tenantId);
      const companyDocRef = doc(db, `company`, tenantId);
      const companyDocSnapshot = await getDoc(companyDocRef);
      console.log(companyDocSnapshot);

      if (companyDocSnapshot.exists()) {
        console.log(companyDocSnapshot.data());
        const companyData = companyDocSnapshot.data();
        return [companyData.companyData]; // Return the fetched companyData
      } else {
        return rejectWithValue("company document does not exist."); // Reject if document not found
      }
    } catch (error) {
      return rejectWithValue(error.message); // Reject with error message
    }
  }
);

export const slice = createSlice({
  name: "companyData",
  initialState,
  reducers: {
    removeCompany: companyAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAndSetCompanyData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAndSetCompanyData.fulfilled, (state, action) => {
      companyAdapter.upsertMany(state, action.payload);
      state.loading = false;
    });
  },
});

const reducer = slice.reducer;
export default reducer;

export const { removeCompany } = slice.actions;

export const {
  selectAll: selectAllCompanys,
  selectTotal: selectTotalCompanys,
} = companyAdapter.getSelectors((state) => state.companyData);
