import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../components/Modal";
import Accordion from "../components/Accordion";
import {
  closeCandidateDetailsModal,
  downloadDocument,
} from "../utils/redux/candidateActions";
import JobPostingDescription from "../components/JobPostingDescription";

const CandidateDetailsModal = () => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeCandidateDetailsModal());
  };

  const handleDownload = (url, type) => {
    dispatch(downloadDocument(url, type));
  };
  // Select only the necessary data
  const isCandidateDetailsModalOpen = useSelector(
    (state) => state.isCandidateDetailsModalOpen
  );
  const selectedCandidate = useSelector((state) => state.selectedCandidate);

  if (!isCandidateDetailsModalOpen || !selectedCandidate) {
    return null;
  }

  const candidate = selectedCandidate;

  return (
    <Modal isOpen={isCandidateDetailsModalOpen} onClose={handleCloseModal}>
      <div className="space-y-4">
        <Accordion title="Contact Information">
          <p>
            <strong>Nom et Prenom:</strong> {candidate.FullName}
          </p>
          <p>
            <strong>Email:</strong>{" "}
            {candidate.ContactInformation
              ? candidate.ContactInformation.Email
              : ""}
          </p>
          <p>
            <strong>Phone:</strong>{" "}
            {candidate.ContactInformation
              ? candidate.ContactInformation.Phone
              : ""}
          </p>
          <p>
            <strong>Address:</strong>{" "}
            {candidate.ContactInformation
              ? candidate.ContactInformation.Address
              : ""}{" "}
            <strong> Ville:</strong>{" "}
            {candidate.ContactInformation
              ? candidate.ContactInformation.City
              : ""}{" "}
            <strong> Pays:</strong>{" "}
            {candidate.ContactInformation.Country
              ? candidate.ContactInformation.Country.name
              : ""}
          </p>
        </Accordion>
        <Accordion title="Score">
          <p className="text-black-600">
            <strong>Classement: </strong>
            {candidate.Score}/100 - Experiences sur 60, Educations sur 20
          </p>
          <p className="text-black-600">
            <strong>Explication:</strong>{" "}
            <JobPostingDescription description={candidate.ScoreReason} />
            {/* <NewlineText text={candidate.ScoreReason} /> */}
          </p>
        </Accordion>
        <Accordion title="Evaluations">
          <p className="text-black-600">
            <strong>Score des Evaluations: </strong>
            {candidate.assessmentScore}{" "}
          </p>
          <p className="text-black-600">
            <strong>Liens des Resultats:</strong>{" "}
            {candidate.assessmentLinks
              ? candidate.assessmentLinks.map((link) => (
                  <p>
                    <a
                      className="text-md text-blue-600 hover:underline hover:text-blue-700 hover:font-semibold"
                      href={`${process.env.REACT_APP_BASE_URL}assessmentresults/${link}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {link}
                    </a>
                  </p>
                ))
              : ""}
          </p>
        </Accordion>

        <Accordion title="Documents">
          <div>
            <strong>CV:</strong>
            <button
              onClick={() => handleDownload(candidate.Resume, "resumesactive")}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 ml-2"
            >
              Telecharger
            </button>
          </div>
          <div>
            <strong>Lettre De Motivation:</strong>{" "}
            {candidate.CoverLetter ? (
              <button
                onClick={() =>
                  handleDownload(candidate.CoverLetter, "coverletteractive")
                }
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 ml-2"
              >
                Telecharger
              </button>
            ) : (
              ""
            )}
          </div>
          {candidate.AdditionalDocuments
            ? candidate.AdditionalDocuments.map((doc, index) => (
                <div key={index}>
                  <strong>Additional Document {index + 1}:</strong>{" "}
                  <a
                    href={doc}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline ml-2"
                  >
                    Download
                  </a>
                </div>
              ))
            : ""}
        </Accordion>

        <Accordion title="Education">
          {candidate.Education.map((edu, index) => (
            <p key={index}>
              <strong>
                {edu.Degree} in {edu.FieldOfStudy}
              </strong>
              , {edu.Institution} ({edu.GraduationYear})
            </p>
          ))}
        </Accordion>

        <Accordion title="Work Experience">
          {candidate.WorkExperience
            ? candidate.WorkExperience.map((exp, index) => (
                <div key={index}>
                  <p>
                    <strong>{exp.Role}</strong> at {exp.CompanyName}, from{" "}
                    {exp.StartDate} to {exp.EndDate}
                  </p>
                  <p>{exp.Responsibilities}</p>
                </div>
              ))
            : ""}
        </Accordion>

        <Accordion title="Skills">
          <p>{candidate.Skills}</p>
        </Accordion>

        <Accordion title="Certifications">
          {candidate.Certifications
            ? candidate.Certifications.map((cert, index) => (
                <p key={index}>
                  {cert.CertificationName} from {cert.IssuingOrganization},
                  obtained on {cert.DateObtained}. Expires on {cert.ExpiryDate}.
                </p>
              ))
            : ""}
        </Accordion>

        <Accordion title="Languages">
          {candidate.Languages
            ? candidate.Languages.map((lang, index) => (
                <p key={index}>
                  {lang.Language} - {lang.Proficiency}
                </p>
              ))
            : ""}
        </Accordion>

        <Accordion title="References">
          {candidate.References
            ? candidate.References.map((ref, index) => (
                <p key={index}>
                  <strong>{ref.RefereeName}</strong>, {ref.Relation},{" "}
                  {ref.ContactInformation}
                </p>
              ))
            : ""}
        </Accordion>

        <Accordion title="Personal Statement">
          <p>{candidate.PersonalStatement}</p>
        </Accordion>

        <Accordion title="Social Media Profiles">
          <p>
            {candidate.SocialMediaProfiles ? (
              <a
                href={candidate.SocialMediaProfiles.LinkedIn}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                LinkedIn
              </a>
            ) : (
              ""
            )}
          </p>
          <p>
            {candidate.SocialMediaProfiles ? (
              <a
                href={candidate.SocialMediaProfiles.Behance}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                Behance
              </a>
            ) : (
              ""
            )}
          </p>
        </Accordion>

        <Accordion title="Portfolio">
          <p>
            <a
              href={candidate.Portfolio}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              View Portfolio
            </a>
          </p>
        </Accordion>

        <Accordion title="Volunteer Experience">
          <p>{candidate.VolunteerExperience}</p>
        </Accordion>

        <Accordion title="Availability">
          <p>{candidate.Availability}</p>
        </Accordion>
      </div>
    </Modal>
  );
};

export default CandidateDetailsModal;
