import { useEffect } from "react"; // For interacting with the DOM

function InitiatePaydunyaCheckout(invoiceToken) {
  useEffect(() => {
    // Make sure PayDunya is loaded (prevents errors if the script loads slowly)
    if (typeof window.PayDunya !== "undefined") {
      window.PayDunya.setup({
        token: invoiceToken, // Token from your backend
        // ...other PayDunya options (see their documentation)
      }).render();
    }
  }, [invoiceToken]); // Re-run if invoiceToken changes
}

export default InitiatePaydunyaCheckout;
