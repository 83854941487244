import React, { useState } from "react";
import Modal from "./Modal";

const StatusModal = ({
  isOpen,
  onClose,
  currentJobPosting,
  onUpdateStatus,
}) => {
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (currentJobPosting) {
      var confirmationMessage = `❗⚠️❗ Etes-vous sûr de changer le statut de ${currentJobPosting.Statut} en ${selectedStatus}❗⚠️❗`;
      if (selectedStatus === "Annuler") {
        confirmationMessage =
          "❗⚠️❗ Etes-vous sûr de vouloir annuler cette candidature ?Tous les candidats qui n'ont pas recu d'offre vont recevoir un mail de rejection dans les 5 prochaines minutes❗⚠️❗";
      } else if (selectedStatus === "Completer") {
        confirmationMessage =
          "❗⚠️❗ Etes-vous sûr de vouloir fermer cette candidature ? Tous les candidats qui n'ont pas recu d'offre vont recevoir un mail de rejection dans les 5 prochaines minutes❗⚠️❗";
      }
      const answer = window.confirm(confirmationMessage);
      if (answer) {
        onUpdateStatus(currentJobPosting.uuid, selectedStatus);
      }
    }

    setSelectedStatus("");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Changer Le Statut Du Poste">
      <form onSubmit={handleSubmit}>
        <select
          name="status"
          className="border-gray-300 rounded"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="">Choisir Statut</option>
          <option value="Submissions">Soumission des candidatures</option>
          <option value="Evaluations">Soumission des tests d'evaluation</option>
          <option value="Entretients">Entretients</option>
          <option value="Offres">Offre d'emploi</option>
          <option value="Completer">Completer</option>
          <option value="Pause">Pause</option>
          <option value="Annuler">Annuler</option>
          {/* Add other statuses as needed */}
        </select>
        {selectedStatus && (
          <p className="text-red-500">
            ⚠️Statut: {selectedStatus}. Veillez confirmer.
          </p>
        )}
        <button
          type="submit"
          className="ml-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
        >
          Save
        </button>
      </form>
    </Modal>
  );
};

export default StatusModal;
