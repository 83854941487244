// CongratulatoryMessage.js
import React from "react";
import Confetti from "react-confetti";
import { useWindowSize } from "@react-hook/window-size";

const CongratulatoryMessage = ({ show }) => {
  const [width, height] = useWindowSize();

  return (
    <>
      {show && (
        <>
          <Confetti width={width} height={height} />
          <div style={styles.messageContainer}>
            <h1 style={styles.message}>
              Félicitations et bienvenue dans l'aventure ProRecruit ! 🎉
            </h1>
            <p style={styles.subMessage}>
              Merci d'avoir rejoint notre communauté révolutionnaire. Un e-mail
              de vérification vient de vous être envoyé. Cliquez sur le lien
              pour activer votre compte et commencez votre voyage vers une
              nouvelle ère du recrutement.
            </p>
            <p style={styles.subMessage}>
              Préparez-vous à transformer votre façon de recruter avec
              ProRecruit !
            </p>
          </div>
        </>
      )}
    </>
  );
};

const styles = {
  messageContainer: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    zIndex: 1000,
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  message: {
    margin: 0,
    fontSize: "2em",
  },
  subMessage: {
    marginTop: "10px",
    fontSize: "1.2em",
  },
};

export default CongratulatoryMessage;
