import { useLocation } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config";

const ThankYouPage = () => {
  const { state } = useLocation();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [messageTitle, setMessageTitle] = useState(
    "Merci pour votre soumission d'emploi !"
  );
  const [messageBody, setMessageBody] = useState(" ");
  const [submitted, setSubmitted] = useState(false);
  const [reviewObject, setReviewObject] = useState({});
  // const [rating, setRating] = useState(0);

  useEffect(() => {
    try {
      console.log(reviewObject);
      console.log(state);
      setReviewObject({
        tenantId: state.tenantId,
        jobId: state.jobId,
        fullName: state.fullName,
        email: state.email,
        phoneNumber: state.phoneNumber,
        rating: rating,
        review: review,
        referrer: state.referrer,
        date: new Date().toISOString(),
      });
    } catch (error) {
      console.log(error);
    }

    switch (state.referrer) {
      case "assessment":
        setMessageTitle(
          `Merci d'avoir participé à notre évaluation des compétences !`
        );
        setMessageBody(
          `Votre feedback est précieux pour nous aider à améliorer notre processus de recrutement. Veuillez prendre quelques instants pour évaluer votre expérience et nous donner votre avis en cliquant sur le lien ci-dessous. Votre retour d'information est essentiel pour nous aider à améliorer nos services. Merci encore pour votre participation!`
        );
        break;
      case "application":
        setMessageTitle("Merci pour votre soumission d'emploi !");
        setMessageBody(`Nous avons reçu votre candidature et reviendrons vers vous
        prochainement. Bonne chance !\n Veuillez prendre quelques instants pour évaluer votre expérience et nous donner votre avis en cliquant sur le lien ci-dessous. Votre retour d'information est essentiel pour nous aider à améliorer nos services. Merci encore pour votre participation!`);
        break;
      default:
        break;
    }
  }, [state, rating, review]);

  const handleStarClick = (value) => {
    setRating(value);
  };

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Submit rating and review to backend
    // For demonstration purposes, we'll just log the values
    console.log(`Rating: ${rating}, Review: ${review}`);
    console.log(reviewObject);
    setMessageBody(`le système
    enverra automatiquement vos réponses et votre score au responsable
    des ressources humaines pour évaluation. Ils seront en mesure de
    consulter vos réponses et de prendre des décisions en conséquence.`);
    setSubmitted(true);
    addDoc(collection(db, "reviews"), reviewObject);
  };
  if (submitted) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8 text-center">
        <h1 className="text-3xl font-bold mb-4">{messageTitle}</h1>
        <p>{messageBody}</p>
        <div className="max-w-4xl mx-auto px-4 py-8 text-center">
          <p>Merci pour votre avis!</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8 text-center">
      <div className="max-w-4xl mx-auto px-4 py-8 text-center">
        <h1 className="text-3xl font-bold mb-4">{messageTitle}</h1>
        <p>{messageBody}</p>
      </div>
      <div className="assessment-container">
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Évaluez votre expérience:
              {[1, 2, 3, 4, 5].map((value) => (
                <span
                  key={value}
                  onClick={() => handleStarClick(value)}
                  style={{
                    cursor: "pointer",
                    color: value <= rating ? "gold" : "gray",
                  }}
                >
                  ★
                </span>
              ))}
            </label>
          </div>
          <div>
            <label>
              commentaires:
              <textarea value={review} onChange={handleReviewChange} />
            </label>
          </div>
          <button
            type="submit"
            style={{
              backgroundColor: "blue",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "darkblue")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "blue")}
            onMouseDown={(e) => (e.target.style.backgroundColor = "purple")}
            onMouseUp={(e) => (e.target.style.backgroundColor = "darkblue")}
          >
            Soumettre
          </button>
        </form>
      </div>
    </div>
  );
};

export default ThankYouPage;
