import React from 'react';
import { useNavigate } from 'react-router-dom';

const AssessmentsTable = ({ data, adminInfo }) => {
  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/UpdateAssessment/${id}`);
  };

  const handleDelete = (id) => {
    // Implement the delete logic here
    console.log(`Delete assessment with id: ${id}`);
  };

  const handleCreateAssessment = () => {
    navigate('/createAssessment');
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-800">Assessments</h3>
        <button
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
          onClick={handleCreateAssessment}
        >
          Create Assessment
        </button>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Title</th>
            <th className="py-2 px-4 border-b">Description</th>
            <th className="py-2 px-4 border-b">Display Name</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((assessment) => (
            <tr key={assessment.id}>
              <td className="py-2 px-4 border-b">{assessment.title}</td>
              <td className="py-2 px-4 border-b">{assessment.description}</td>
              <td className="py-2 px-4 border-b">{assessment.displayName}</td>
              <td className="py-2 px-4 border-b">
                {assessment.target === adminInfo.tenantId? (
                  <>
                    <button
                      className="bg-green-500 text-white font-bold py-1 px-3 rounded mr-2"
                      onClick={() => handleEdit(assessment.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 text-white font-bold py-1 px-3 rounded"
                      onClick={() => handleDelete(assessment.id)}
                    >
                      Delete
                    </button>
                  </>
                ):""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AssessmentsTable;
