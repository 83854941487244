import { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, analytics, db } from "../firebase-config"; // Update with your Firebase config path

const AssignJobOfferModal = ({ isOpen, onClose, jobOffers, users, userData }) => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  if (!isOpen) return null;

  const handleAssign = async () => {
    if (!selectedJob || !selectedUser) {
      alert("Please select both a job offer and a recruiter.");
      return;
    }

    const confirmAssignment = window.confirm(
      `Are you sure you want to assign the job offer "${selectedJob.Titre}" to ${selectedUser.email}?`
    );

    if (confirmAssignment) {
      try {
        const jobOfferDoc = doc(db, `job-descriptions/${userData.tenantId}/active`, `${selectedJob.uuid}`);
        await updateDoc(jobOfferDoc, {
          InformationsDeContact: {
            NomDuContactRH: selectedUser.displayName || "",
            Email: selectedUser.email,
            Telephone: selectedUser.phone || "",
          },
        });
        alert("Job offer assigned successfully.");
        onClose();
      } catch (error) {
        console.error("Error assigning job offer:", error);
        alert("There was an error assigning the job offer. Please try again.");
      }
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-8 max-w-md">
        <h2 className="text-xl font-bold mb-4">Assign Job Offer</h2>

        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Select Job Offer:</label>
          <select
            className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            onChange={(e) => setSelectedJob(JSON.parse(e.target.value))}
          >
            <option value="">-- Select a Job Offer --</option>
            {jobOffers.map((job) => (
              <option key={job.JobID} value={JSON.stringify(job)}>
                {job.Titre} - {job.Entreprise}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Select Recruiter:</label>
          <select
            className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            onChange={(e) => setSelectedUser(JSON.parse(e.target.value))}
          >
            <option value="">-- Select a Recruiter --</option>
            {users.map((user) => (
              <option key={user.email} value={JSON.stringify(user)}>
                {user.name} - {user.email}
              </option>
            ))}
          </select>
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleAssign}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Assign
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignJobOfferModal;
