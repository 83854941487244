import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from "firebase/auth";
import Navbar from "../components/Navbar";
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase-config';
import { collection, setDoc, doc } from 'firebase/firestore';
import { ColorRing } from "react-loader-spinner";
import { fetchAndSetUserData, selectAllUsers } from "../utils/redux/userDataSlice";
import './CreateAssessment.css';
import { useAuth } from "../useAuth";
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const CreateAssessment = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [numberOfQuestionToAsk, setNumberOfQuestionToAsk] = useState(0);
    const [displayName, setDisplayName] = useState('');
    const [target, setTarget] = useState('all');
    const [questions, setQuestions] = useState([]);
    const userData = useSelector(selectAllUsers)[0];
    const [hasAnyLicense, setHasAnyLicense] = useState(false);
    const [proLicenseCount, setProLicenseCount] = useState(0);
    const [basicLicenseCount, setBasicLicenseCount] = useState(0);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);

    const [modifications, setModifications] = useState([{
        createdOn: new Date().toLocaleDateString(),
        createdByEmail: currentUser.email||"",
        createdBy: currentUser.displayName||"",
    }]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            if (currentUser) {
                await dispatch(fetchAndSetUserData(currentUser.email));
                setLoading(false)
            }
        };
        fetchData();
    }, [currentUser, dispatch]);

    const navigate = useNavigate();


  const toggleCreateModal = () => {
    console.log(hasAnyLicense);
    console.log(basicLicenseCount);
    console.log(proLicenseCount);
    hasAnyLicense
      ? setIsCreateModalOpen(!isCreateModalOpen)
      : setIsLicenseModalOpen(true);
  };

    
  const handleSignOut = useCallback(async () => {
    try {
      await fetch(`${API_ENDPOINT}/api/v1/usersession/sessionLogout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenantId: currentUser.tenantId,
        }),
        credentials: "include",
      });

      await signOut(auth);
    } catch (error) {
      console.error(
        `Sign-out failed. currentUser ${JSON.stringify(currentUser)}, ${error}`
      );
    }
  }, [currentUser]);
  const handleTypeChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].type = value;
    // Adjust options array size for MultipleChoice vs SingleChoice
    if (value === 'SingleChoice') {
      newQuestions[index].user.answer = ['', '', '', ''];
    } else if (value === 'MultipleChoice') {
      newQuestions[index].user.answer = ['', '', '', ''];
    }
    setQuestions(newQuestions);
  };

    const handleAddQuestion = () => {
        setQuestions([...questions, {
            id: questions.length + 1,
            category: '',
            subCategory: '',
            level: '',
            language: '',
            type: 'SingleChoice',
            system: {
                prompt: '',
                question: '',
                score: 0,
                duration: 0,
                options: [
                    { choice: '', score: 0, isSelected: false },
                    { choice: '', score: 0, isSelected: false }
                ],
            },
            user: {
                answer: [{ choice: '', score: 0, isSelected: false },
                    { choice: '', score: 0, isSelected: false }],
                score: 0,
                duration: 0,
            }
        }]);
    };

    const handleRemoveQuestion = (index) => {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    };

    const handleInputChange = (index, field, value) => {
        const newQuestions = [...questions];
        if (field.includes("system.")) {
            const key = field.split('.')[1];
            newQuestions[index].system[key] = value;
        } else {
            newQuestions[index][field] = value;
        }
        setQuestions(newQuestions);
    };

    const handleOptionChange = (questionIndex, optionIndex, field, value) => {
        const newQuestions = [...questions];
        newQuestions[questionIndex].user.answer[optionIndex][field] = value;
        setQuestions(newQuestions);
    };

    const handleAddOption = (questionIndex) => {
        const newQuestions = [...questions];
        newQuestions[questionIndex].user.answer.push({ choice: '', score: 0, isSelected: false });
        setQuestions(newQuestions);
    };

    const handleRemoveOption = (questionIndex, optionIndex) => {
        const newQuestions = [...questions];
        if (newQuestions[questionIndex].user.answer.length > 2) {
            newQuestions[questionIndex].user.answer.splice(optionIndex, 1);
            setQuestions(newQuestions);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (numberOfQuestionToAsk > questions.length) {
            alert('The number of questions to ask cannot be greater than the number of provided questions.');
            return;
        }

        try {
            const docId = Date.now().toString(); // Generate a unique document ID

        await setDoc(doc(db, 'assessments', docId), {
            id: docId, // Include the document ID in the data
            description,
            title,
            numberOfQuestionToAsk,
            displayName,
            target,
            modifications,
            questions,
        });

            alert('Assessment created successfully!');
            navigate("/dashboard"); // Redirect to the list of assessments or another page
        } catch (error) {
            console.error('Error creating assessment:', error);
            alert('Error creating assessment. Please try again.');
        }
    };

    if (loading) {
        return (
          <div className="flex justify-center items-center h-screen">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        );
      }
      return (
        <div className="container mx-auto px-4 py-8">
            <Navbar 
                onSignOut={handleSignOut} 
                onCreateJob={toggleCreateModal} 
                userData={userData} 
                extraButton={{ label: "Liste Des Offres", path: "/jobpostings" }} 
            />
<Tooltip id="my-tooltip" />
            <form onSubmit={handleSubmit} className="create-assessment-form">
                <h2>Create New Assessment</h2>

                <section>
                    <div className="form-group">
                        <label>
                            Title
                            <a data-tooltip-id="my-tooltip" data-tooltip-content="Enter a title for the assessment." data-tooltip-place="top"> <FontAwesomeIcon icon={faInfoCircle} /> </a>
                            
                        </label>
                        <input 
                            type="text" 
                            value={title} 
                            onChange={(e) => setTitle(e.target.value)} 
                            required 
                        />
                    </div>

                    <div className="form-group">
                        <label>
                            Description
                            <a data-tooltip-id="my-tooltip" data-tooltip-content="Provide a brief description of the assessment." data-tooltip-place="top"> <FontAwesomeIcon icon={faInfoCircle} /> </a>
                            
                        </label>
                        <textarea 
                            value={description} 
                            onChange={(e) => setDescription(e.target.value)} 
                            required 
                        />
                    </div>

                    <div className="form-group">
                        <label>
                            Display Name
                            <a data-tooltip-id="my-tooltip" data-tooltip-content="This name will be displayed to the users." data-tooltip-place="top"> <FontAwesomeIcon icon={faInfoCircle} /> </a>
                            
                        </label>
                        <input 
                            type="text" 
                            value={displayName} 
                            onChange={(e) => setDisplayName(e.target.value)} 
                            required 
                        />
                    </div>

                    <div className="form-group">
                        <label>
                            Number of Questions to Ask
                            <a data-tooltip-id="my-tooltip" data-tooltip-content="Specify the number of questions to be asked in this assessment." data-tooltip-place="top"> <FontAwesomeIcon icon={faInfoCircle} /> </a>
                            
                        </label>
                        <input 
                            type="number" 
                            value={numberOfQuestionToAsk} 
                            onChange={(e) => setNumberOfQuestionToAsk(e.target.value)} 
                            required 
                        />
                    </div>

                    <div className="form-group">
                        <label>
                            Target
                            <a data-tooltip-id="my-tooltip" data-tooltip-content="Select the target audience for this assessment." data-tooltip-place="top"> <FontAwesomeIcon icon={faInfoCircle} /> </a>
                            
                        </label>
                        <select 
                            value={target} 
                            onChange={(e) => setTarget(e.target.value)} 
                            required
                        >
                            <option value="all">All</option>
                            <option value={userData.tenantId}>{userData.tenantId}</option>
                        </select>
                    </div>
                </section>

                <section className="questions-section">
                    <h3>Questions</h3>
                    {questions.map((question, index) => (
                        <div>Question #{question.id}
                        <div key={question.id} className="question-block">
                            <div className="form-group">
                                <label>Category</label>
                                <input 
                                    type="text" 
                                    value={question.category} 
                                    onChange={(e) => handleInputChange(index, 'category', e.target.value)} 
                                    required 
                                />
                            </div>

                            <div className="form-group">
                                <label>SubCategory</label>
                                <input 
                                    type="text" 
                                    value={question.subCategory} 
                                    onChange={(e) => handleInputChange(index, 'subCategory', e.target.value)} 
                                    required 
                                />
                            </div>

                            <div className="form-group">
                                <label>Level</label>
                                <input 
                                    type="text" 
                                    value={question.level} 
                                    onChange={(e) => handleInputChange(index, 'level', e.target.value)} 
                                    required 
                                />
                            </div>

                            <div className="form-group">
                                <label>Language</label>
                                <input 
                                    type="text" 
                                    value={question.language} 
                                    onChange={(e) => handleInputChange(index, 'language', e.target.value)} 
                                    required 
                                />
                            </div>

                            <div className="form-group">
                                <label>Question Type</label>
                                <select 
                                    value={question.type} 
                                    onChange={(e) => handleTypeChange(index, e.target.value)} 
                                    required
                                >
                                    <option value="SingleChoice">Single Choice</option>
                                    <option value="MultipleChoice">Multiple Choice</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Score</label>
                                <input 
                                    type="number" 
                                    value={question.system.score} 
                                    onChange={(e) => handleInputChange(index, 'system.score', e.target.value)} 
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Question</label>
                                <textarea 
                                    value={question.system.question} 
                                    onChange={(e) => handleInputChange(index, 'system.question', e.target.value)} 
                                    required 
                                />
                            </div>

                            {question.type === 'SingleChoice' || question.type === 'MultipleChoice' ? (
                                <div className="form-group options-group">
                                    <label>Options</label>
                                    {question.user.answer.map((option, optIndex) => (
                                        <div key={optIndex} className="option-block">
                                            <input 
                                                type="text" 
                                                placeholder="Option" 
                                                value={option.choice} 
                                                onChange={(e) => handleOptionChange(index, optIndex, 'choice', e.target.value)} 
                                                required 
                                            />
                                            <input 
                                                type="number" 
                                                placeholder="Score" 
                                                value={option.score} 
                                                onChange={(e) => handleOptionChange(index, optIndex, 'score', e.target.value)} 
                                                required 
                                            />
                                            <button 
                                                className="remove-question-btn"
                                                type="button" 
                                                onClick={() => handleRemoveOption(index, optIndex)} 
                                                disabled={question.user.answer.length <= 2}
                                            >
                                                Remove Option
                                            </button>
                                        </div>
                                    ))}
                                    <button 
                                                className="add-question-btn"
                                        type="button" 
                                        onClick={() => handleAddOption(index)}
                                    >
                                        Add Option
                                    </button>
                                </div>
                            ) : null}

                            <div className="form-group">
                                <label>Duration (secondes)</label>
                                <input 
                                    type="number" 
                                    value={question.system.duration} 
                                    onChange={(e) => handleInputChange(index, 'system.duration', e.target.value)} 
                                    required 
                                />
                            </div>

                            <button 
                                                className="remove-question-btn"
                                type="button" 
                                onClick={() => handleRemoveQuestion(index)}
                                disabled={questions.length <= 1}
                            >
                                Remove Question
                            </button>
                        </div></div>
                    ))}
                    <button 
                        type="button" 
                                                className="add-question-btn"
                        onClick={handleAddQuestion}
                    >
                        Add Question
                    </button>
                </section>

                <button className="submit-btn" type="submit">Create Assessment</button>
            </form>
        </div>
    );
};

export default CreateAssessment;
