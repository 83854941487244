import React from "react";
import TawkToChat from "../components/TawkToChat";

const Pricing = () => {
  return (
    <div className="bg-teal-100 py-12">
      {" "}
      {/* Background Color */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            ProRecruit Pricing
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Toutes les offres comprennent un soutien illimité pendant la période
            de recrutement. De plus, nous assurons une protection, une
            gouvernance et une sécurité des données de haute qualité, conformes
            aux principes du RGPD.
          </p>
          <p className="mt-4 text-lg text-gray-600">
            Éliminez les préjugés dans les processus de recrutement. 1 crédit
            équivaut à 1 candidature Professionnelle ou 5 candidatures Basiques.
          </p>
          <p className="mt-2 text-lg text-gray-600">
            Les paiements peuvent être effectués en dollars, en euros, en FCFA,
            en nairas ou en cedis.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Basic Package */}
          <div className="bg-white rounded-lg shadow-md p-6 transform hover:scale-105 transition duration-300 ease-in-out">
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              Basic Package
            </h3>
            <div className="bg-indigo-600 text-white rounded-t-lg py-2 px-4 mb-4 text-center text-lg font-semibold">
              XOF 10,000
            </div>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>
                <span className="font-semibold">Caractéristiques:</span>
              </li>
              <li>Gestion des candidats</li>
              <li>Limite à 1000 candidatures par offre</li>
              <li>6 mois d'accessibilité</li>
            </ul>
            <div className="mt-6 text-center">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Essayez Gratuitement Maintenant
              </button>
            </div>
          </div>

          {/* Professional Package - Featured */}
          <div className="bg-white rounded-lg shadow-md p-6 relative transform hover:scale-105 transition duration-300 ease-in-out">
            <span className="absolute top-0 right-0 -mt-6 -mr-6 bg-yellow-400 h-12 w-12 rounded-full flex items-center justify-center text-white text-xl font-bold shadow-lg">
              ★
            </span>
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              Professional Package
            </h3>
            <div className="bg-indigo-600 text-white rounded-t-lg py-2 px-4 mb-4 text-center text-lg font-semibold">
              XOF 80,000
            </div>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>
                <span className="font-semibold">Caractéristiques:</span>
              </li>
              <li>Pack Basic inclus (12 mois d'accessibilité)</li>
              <li>Candidature illimitée</li>
              <li>Notification automatique par email</li>
              <li>Évaluations psycho-analytiques des candidats</li>
              <li>Analyse et classement automatique des CV</li>
            </ul>
            <div className="mt-6 text-center">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Commencez Maintenant
              </button>
            </div>
          </div>

          {/* Premium Package */}
          <div className="bg-white rounded-lg shadow-md p-6 transform hover:scale-105 transition duration-300 ease-in-out">
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              Premium Package
            </h3>
            <div className="bg-indigo-600 text-white rounded-t-lg py-2 px-4 mb-4 text-center text-lg font-semibold">
              XOF 100,000
            </div>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>
                <span className="font-semibold">Caractéristiques:</span>
              </li>
              <li>Pack Professionnel inclus</li>
              <li>Sourcing des meilleurs candidats</li>
              <li>
                Page de candidature et notifications des candidats avec votre
                marque personnelle
              </li>
            </ul>
            <div className="mt-6 text-center">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Commencez Maintenant
              </button>
            </div>
          </div>

          {/* PACK D'ABONNEMENT */}
          <div className="bg-white rounded-lg shadow-md p-6 transform hover:scale-105 transition duration-300 ease-in-out">
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              PACK D'ABONNEMENT
            </h3>
            <div className="bg-indigo-600 text-white rounded-t-lg py-2 px-4 mb-4 text-center text-lg font-semibold">
              XOF 150,000 + XOF 64,000/OFFRE
            </div>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>
                <span className="font-semibold">Caractéristiques:</span>
              </li>
              <li>Pack Professionnel inclus</li>
              <li>20% de réduction sur chaque offre d'emploi créée</li>
              <li>Gestionnaire de comptes dédié</li>
              <li>Accès illimité aux données, y compris les archives</li>
            </ul>
            <div className="mt-6 text-center">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Commencez Maintenant
              </button>
            </div>
          </div>
        </div>
      </div>
      <TawkToChat/>
    </div>
  );
};

export default Pricing;
