import React from "react";
import CandidateItem from "./CandidateItem";

const CandidateList = ({ candidates, tenantId, jobUuid }) => {
  return (
    <div className="mt-6">
      {candidates.map((candidate) => (
        <CandidateItem key={candidate.uuid} candidate={candidate} tenantId={tenantId} jobUuid={jobUuid} />
      ))}
    </div>
  );
};

export default CandidateList;
