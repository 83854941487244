import React from 'react';
import DialogflowMessenger from '../components/DialogflowMessenger';

const TestPage = () => {
  return (
    <div className="App">
      <DialogflowMessenger />
      {/* Other components */}
    </div>
  );

}

export default TestPage;
