import React, { useState } from 'react';
import JobPostingDescription from './JobPostingDescription';

const CandidateScoreReason = ({ description }) => {
  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="space-y-4">
      {description?description.map((item, index) => (
        <div key={index} className="border-b border-gray-300 pb-4">
          <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleExpand(index)}>
            <div className="font-medium text-gray-700">
              {item.name}: {item.score} points
            </div>
            <button className="bg-readMoreButtonBg text-blue-500 hover:underline">
              {expanded === index ? '- Voir moins...' : '+ Voir plus...'}
            </button>
          </div>
          {expanded === index && (
            <div className="mt-2 text-gray-600 ">
              {Array.isArray(item.explication_score) ? (
                item.explication_score.map((explication, idx) => (
                  <div className="border-b border-gray-300 pb-4"><p key={idx} className="mt-1 text-sm">
                    <JobPostingDescription description={explication} />
                  </p></div>
                ))
              ) : (
                <p className="mt-1 text-sm">{item.explication_score}</p>
              )}
            </div>
          )}
        </div>
      )):<div className="border-b border-gray-300 pb-4"><p className="mt-1 text-sm">
      "Cette fonctionnalité n'est disponible que pour les licences Pro. Pour mettre à niveau, veuillez nous contacter à customer@keobrand.com"
    </p></div>}
    </div>
  );
};

export default CandidateScoreReason;
