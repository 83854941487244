// src/firebase-config.js
import { initializeApp, getApp, getApps } from "firebase/app";
import { inMemoryPersistence, getAuth, initializeAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

let app, auth, analytics, db;

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

if (!getApps().length) {
  // Initialize Firebase
  app = initializeApp(firebaseConfig);

  // Initialize Firebase Authentication and get a reference to the service
  auth = initializeAuth(app, { persistence: inMemoryPersistence });
  auth.useDeviceLanguage();
  analytics = getAnalytics(app);
  db = getFirestore(app);
} else {
  app = getApp();
  auth = getAuth(app);
  analytics = getAnalytics(app);
  db = getFirestore(app);
}

export { auth, analytics, db };
