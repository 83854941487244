import React, { useState, useEffect, useCallback } from "react";
import { signOut } from "firebase/auth";
import Navbar from "../components/Navbar";
import TawkToChat from "../components/TawkToChat";
import { auth, analytics, db } from "../firebase-config";
import { useSelector, useDispatch } from "react-redux";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { sanitizeInput } from "../utils/inputSanitizer";
import { collection, doc, setDoc, getDoc, updateDoc, increment } from "firebase/firestore";
import AssignJobOfferModal from "../components/AssignJobOfferModal"
import UsersTable from "../components/UsersTable";
import AssessmentsTable from "../components/AssessmentsTable";
import {
  fetchJobPostings,
  selectJobPostings,
  selectJobPostingsLoading,
  selectJobPostingsError,
  loadMoreJobPostings,
} from "../utils/redux/jobPostingsSlice";
import {
  selectAllUsers,
  fetchAndSetUserData,
} from "../utils/redux/userDataSlice";
import CreateJobModal from "../components/createJobModal";
import LicenseRequiredModal from "../components/LicenseRequiredModal";
import {
  fetchAndSetCompanyData,
  selectAllCompanys,
} from "../utils/redux/companySlice";
import {
  fetchAssessments,
  selectAssessments,
} from "../utils/redux/assessmentSlice";
import { useAuth } from "../useAuth";
import { useNavigate } from "react-router-dom"; // Import useNavigate



const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const DashboardPage = () => {
  const { currentUser } = useAuth();
  console.log("currentUser")
  console.log(currentUser)
  const jobPostings = useSelector(selectJobPostings);
  const loading = useSelector(selectJobPostingsLoading);
  const error = useSelector(selectJobPostingsError);
  const assessments = useSelector(selectAssessments);
  const [activeTab, setActiveTab] = useState("users"); // Default tab
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [hasAnyLicense, setHasAnyLicense] = useState(false);
  const [proLicenseCount, setProLicenseCount] = useState(0);
  const [basicLicenseCount, setBasicLicenseCount] = useState(0);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [newJobData, setNewJobData] = useState({});
  const userData = useSelector(selectAllUsers)[0];
  const usersData = useSelector(selectAllUsers);
  console.log(usersData)
  const companyData = useSelector(selectAllCompanys);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
    if (currentUser) {
      console.log(currentUser)
      const u = await dispatch(fetchAndSetUserData(currentUser.email));
      console.log(`done with u`);
      console.log(u)
      console.log(u.payload)
      const c = await dispatch(fetchAndSetCompanyData(u.payload.tenantId));
      console.log(c)
      const a = await dispatch(fetchAssessments({ tenantId: u.payload.tenantId }));
      console.log(a)
      setProLicenseCount(c.payload[0].proLicense);
      setBasicLicenseCount(c.payload[0].basicLicense);
      if (c?c.payload.proLicense:0 + c?c.payload.basicLicense:0 <= 0) {
        setHasAnyLicense(false);
      } else {
        setHasAnyLicense(true);
      }
    }}
    fetchData();
  }, [currentUser, dispatch]);

  
  useEffect(() => {
    if (companyData) {
      console.log(companyData)
      
      setProLicenseCount(companyData[0]?companyData[0].proLicense:0)
      setBasicLicenseCount(companyData[0]?companyData[0].basicLicense:0)
      if (userData) {
        dispatch(fetchJobPostings({ tenantId: userData.tenantId }));
      }
    }
  }, [userData, companyData, currentUser, dispatch]);
  // Handle tab switching
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Handle Assign Job Offer modal
  const handleOpenAssignModal = () => {
    setIsAssignModalOpen(true);
  };

  const handleBuyMoreLicensesClick = () => {
    navigate("/checkout");
  };
  const handleCreateJobChange = (e) => {
    console.log(newJobData)
    console.log(e)
    // Check if e is an event or a value from react-select
    if (e && e.target) {
      // Handle standard input change
      setNewJobData({ ...newJobData, [e.target.name]: e.target.value });
  } else {
      // Handle react-select change for assessments
    console.log(e)
      
    // Merge new assessments with the existing ones
    setNewJobData(newJobData => ({
      ...newJobData,
      assessments: Array.isArray(e) ? e : [...(newJobData.assessments || []), e]
    }));
  }
  };


  const handleGetLicense = () => {
    // Logique pour rediriger l'utilisateur vers la page d'achat de licence
    window.location.href = "/checkout"; // Exemple
  };

  const handleLearnMore = () => {
    // Logique pour rediriger l'utilisateur vers la page d'informations sur les licences
    window.location.href = "/pricing"; // Exemple
  };
  const generateShortCode = async (docRef) => {
    const applicationUrlIdDocRef = doc(db, "job", "5tdpJAv6JNwmawkDKPSH");
    const applicationUrlIdDoc = await getDoc(applicationUrlIdDocRef);

    if (applicationUrlIdDoc.exists()) {
      const data = applicationUrlIdDoc.data();
      while (true) {
        let applicationUrlId = Math.random().toString(36).substring(2, 8);
        if (!data[applicationUrlId]) {
          await setDoc(
            applicationUrlIdDocRef,
            { [applicationUrlId]: docRef },
            { merge: true }
          );
          return applicationUrlId;
        }
      }
    } else {
      console.error("Document '5tdpJAv6JNwmawkDKPSH' not found!");
      throw new Error("Document not found for short code generation");
    }
  };
  
  const handleCreateJobSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData(event.target);
      console.log(formData)
      console.log(newJobData)
      const newJobDataObject = Object.fromEntries(formData.entries());
      console.log(newJobDataObject)
      const sanitizedJobData = Object.fromEntries(
        Object.entries(newJobDataObject).map(([key, value]) => [
          key,
          sanitizeInput(value),
        ])
      );

      logEvent(analytics, "select_content", {
        content_type: "handleCreateJobSubmit",
        content_id: newJobDataObject ? newJobDataObject.Titre : "",
      });
      console.log(sanitizedJobData)
      const docRef = doc(
        collection(db, `job-descriptions/${userData.tenantId}/active`)
      );

      const applicationUrlId = await generateShortCode(docRef);
      var licenseUpdateJson = {"companyData.proLicense": increment(-1)}
      sanitizedJobData.type = "Basic"
      if (sanitizedJobData.jobOfferType === "Basic"){
         licenseUpdateJson = {"companyData.basicLicense": increment(-1)}
         sanitizedJobData.type = "Prof"
      }

      const jobPosting = {
        licenseType: sanitizedJobData.jobOfferType,
        JobID: applicationUrlId,
        resumeAnalysisQuota: 10000,
        type: sanitizedJobData.type,
        uuid: docRef.id,
        Titre: sanitizedJobData.Titre || "",
        Entreprise: sanitizedJobData.Entreprise,
        EntrepriseDescription: sanitizedJobData.DescriptionDeLentreprise,
        Lieu: sanitizedJobData.Lieu,
        TypeDePoste: sanitizedJobData.TypeDePoste,
        Secteur: sanitizedJobData.Secteur,
        CategorieDeRole: sanitizedJobData.CategorieDeRole,
        Description: sanitizedJobData.Description,
        Qualifications: sanitizedJobData.Qualifications,
        NiveauDExperience: sanitizedJobData.NiveauDExperience,
        CompetencesRequises: sanitizedJobData.CompetencesRequises
          ? sanitizedJobData.CompetencesRequises.split(",")
          : [],
        additionalInformation: sanitizedJobData.additionalInformation,
        // FourchetteDeSalaire: sanitizedJobData.FourchetteDeSalaire,
        Avantages: sanitizedJobData.Avantages
          ? sanitizedJobData.Avantages.split(",")
          : [],
        DateLimiteDeCandidature: sanitizedJobData.DateLimiteDeCandidature,
        PublicationDate: sanitizedJobData.DateDePublication,
        ProcedureDeCandidature: "",
        minimumRelevantYearsOfExperience:
        newJobDataObject.minimumRelevantYearsOfExperience,
        linkToApply: `${process.env.REACT_APP_BASE_URL}jobs/${applicationUrlId}`,
        Responsibilities: sanitizedJobData.Responsibilities || "",
        InformationsDeContact: {
          NomDuContactRH: sanitizedJobData.NomDuContactRH,
          Email: sanitizedJobData.Email,
          Telephone: sanitizedJobData.Telephone,
        },
        CreatedBy: {
          fullName: userData.displayName||"",
          email: userData.email,
          uuid: userData.uuid || "",
        },
        createdOn: new Date().toISOString(),
        Statut: "Submissions",
        tenantId: userData.tenantId,
        assessments: newJobData.assessments,
        // LogoDeLentreprise: sanitizedJobData.LogoDeLentreprise,
        // DeclarationDeDiversite: sanitizedJobData.DeclarationDeDiversite,
      };
      console.log(sanitizedJobData)
      const docAdded = await setDoc(docRef, jobPosting);
      console.log(docAdded);
      const companyRef = doc(
        collection(db, `company`), `${userData.tenantId}`
      );
      
      const putCompany = await updateDoc(companyRef,licenseUpdateJson)
      console.log(putCompany);


      logEvent(analytics, "createdJobPosting", {
        tenantId: userData.tenantId,
        jobId: jobPosting.uuid,
        email: userData.email,
      });

      await fetch(`${API_ENDPOINT}/api/v1/tasks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: `https://us-central1-${PROJECT_ID}.cloudfunctions.net/job-description-txt-builder`,
          payload: jobPosting,
        }),
      });

      alert("Offre d'emploi ajoutée avec succès. 👏😎🎊");
      setIsCreateModalOpen(false);
    } catch (error) {
      console.error("Error creating job posting:", error);
      alert(
        "Une erreur s'est produite. Veuillez réessayer plus tard. Si l'erreur persiste, veuillez contacter info@keobrand.com"
      );
    }
  };
  
  const handleSignOut = useCallback(async () => {
    try {
      await fetch(`${API_ENDPOINT}/api/v1/usersession/sessionLogout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenantId: currentUser.tenantId,
        }),
        credentials: "include",
      });

      await signOut(auth);
    } catch (error) {
      console.error(
        `Sign-out failed. currentUser ${JSON.stringify(currentUser)}, ${error}`
      );
    }
  }, [currentUser]);
  
  const handleCloseAssignModal = () => {
    setIsAssignModalOpen(false);
  };
  const toggleCreateModal = () => {
    console.log(hasAnyLicense);
    console.log(basicLicenseCount);
    console.log(proLicenseCount);
    hasAnyLicense
      ? setIsCreateModalOpen(!isCreateModalOpen)
      : setIsLicenseModalOpen(true);
  };


  return (
    <div className="container mx-auto px-4 py-8">
      <Navbar onSignOut={handleSignOut} onCreateJob={toggleCreateModal} userData={userData} extraButton={{ label: "Liste Des Offres", path: "/jobpostings" }}/>
      {/* License Information Section */}
      {isLicenseModalOpen && (
        <LicenseRequiredModal
          isOpen={isLicenseModalOpen}
          onClose={() => setIsLicenseModalOpen(false)}
          onGetLicense={handleGetLicense}
          onLearnMore={handleLearnMore}
        />
      )}

      <CreateJobModal
        isOpen={isCreateModalOpen}
        onClose={toggleCreateModal}
        onSubmit={handleCreateJobSubmit}
        onChange={handleCreateJobChange}
        data={newJobData}
        company={companyData}
      />
      <div className="flex flex-col md:flex-row gap-4 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-md flex-1">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">
            Basic License
          </h3>
          <p className="text-gray-600 mb-4">
            Vous avez {basicLicenseCount} license Basic.
          </p>
          <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded" onClick={handleBuyMoreLicensesClick}>
            Buy More Licenses
          </button>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md flex-1">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">
            Pro License
          </h3>
          <p className="text-gray-600 mb-4">
          Vous avez {proLicenseCount} license Pro.
          </p>
          <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded" onClick={handleBuyMoreLicensesClick}>
            Buy More Licenses
          </button>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="bg-white px-6 rounded-lg shadow-md">
        <div className="flex px-2 border-b border-gray-200 mb-4">
          <button
            className={`py-2 px-4 text-gray-800 font-medium ${
              activeTab === "users" && "bg-green-800 border-b-2"
            }`}
            onClick={() => handleTabClick("users")}
          >
            Users
          </button>
          <button
            className={`py-2 px-4 text-gray-800 font-medium ${
              activeTab === "jobOffers" && "bg-green-800 border-b-2"
            }`}
            onClick={() => handleTabClick("jobOffers")}
          >
            Job Offers
          </button>
          <button
            className={`py-2 px-4 text-gray-800 font-medium ${
              activeTab === "Assessments" && "bg-green-800 border-b-2"
            }`}
            onClick={() => handleTabClick("Assessments")}
          >
            Test d'Evaluation
          </button>
        </div>

        {/* Tab Content */}
        {activeTab === "users" && <UsersTable adminInfo={userData} data={usersData} />}
        {activeTab === "jobOffers" && (
          <JobOffersTable
            data={jobPostings}
            onOpenAssignModal={handleOpenAssignModal}
          />
        )}
        {activeTab === "Assessments" && (
          <AssessmentsTable
            data={assessments}
            adminInfo={userData}
            onOpenAssignModal={handleOpenAssignModal}
          />
        )}
      </div>

      {/* Assign Job Offers Modal */}
      <AssignJobOfferModal
        isOpen={isAssignModalOpen}
        onClose={handleCloseAssignModal}
        jobOffers={jobPostings}
        users={usersData}
        userData={userData}
      />
    </div>
  );
};


// Job Offers Table Component
const JobOffersTable = ({ data, onOpenAssignModal }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const handleEdit = (jobId, tenantId) => {
    navigate(`/jobpostings/${jobId}/tenants/${tenantId}`);
  };
  return (
    <table className="min-w-full">
      <thead>
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            ID
          </th>
          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
            Titre
          </th>
          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
            Recruteur
          </th>
          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
            Statut
          </th>
          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
            Date Limite
          </th>
          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
            License
          </th>
          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
            Options
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((jobPosting, index) => (
          <tr key={index} className="bg-white">
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {jobPosting.JobID}
          </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {jobPosting.Titre}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {jobPosting.InformationsDeContact.Email}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {jobPosting.Statut}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {new Date(jobPosting.DateLimiteDeCandidature).toLocaleDateString("fr-FR")}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            {jobPosting.type}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <button onClick={() => handleEdit(jobPosting.uuid, jobPosting.tenantId)} className="text-indigo-600 hover:text-indigo-900 mr-2">
                Edit
              </button>
            </td>
          </tr>
        ))}
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <button
            onClick={onOpenAssignModal}
            className="text-blue-600 hover:text-blue-900 mr-2"
          >
            Assign
          </button>
        </td>
      </tbody>
    </table>
  );
};

export default DashboardPage;
