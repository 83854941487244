import React, { useState } from "react";
import {displaySanitizedInput} from "../utils/inputSanitizer"

const JobPostingDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <p
        className={`text-gray-700 ${
          isExpanded ? "" : "line-clamp-2"
        } overflow-hidden`}
      >
        {displaySanitizedInput(description)}
      </p>
      <button
        onClick={toggleDescription}
        className="text-blue-500 bg-readMoreButtonBg font-semibold hover:underline mt-2"
      >
        {isExpanded ? "Montrer moins" : "Voir plus..."}
      </button>
    </div>
  );
};

export default JobPostingDescription;
